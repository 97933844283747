import React,{Component} from 'react';
import cookie from 'react-cookies';
import { deliveryId, pickupId, cateringId, reservationId } from "../Helpers/Config";

class Refpage extends Component {

  constructor(props) {
        super(props);
		
		let slugtext = (typeof this.props.match.params.slugtext !== "undefined" ? this.props.match.params.slugtext : "");
		
		if(slugtext === 'delivery') {
			cookie.save('triggerAvlPop', deliveryId);
		} else if(slugtext === 'pickup') {
			cookie.save('triggerAvlPop', pickupId);
		} else if(slugtext === 'catering') {
			cookie.save('triggerAvlPop', cateringId);
		} else if(slugtext === 'reservation') {
			cookie.save('triggerAvlPop', reservationId);
		} else {
			cookie.remove("triggerAvlPop");
		}
		
		this.props.history.push('/');
  }		

  render() {
    return (<div></div>);
  }
}

export default Refpage;