/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { appId, apiUrl, apiUrlV2, deliveryId, timThumpUrl } from "../Helpers/Config";
import { getReferenceID, stripslashes, showPriceValue, callImage, showLoader, hideLoader, showCustomAlert, showCartLst, showAlert, removePromoCkValue } from "../Helpers/SettingHelper";

import { GET_PRODUCT_LIST } from '../../actions';
var Parser = require('html-react-parser');
var qs = require('qs');

class ProductList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: '',
      selectedslugType: '',
      productSubcatlist: [],
      productCommon: []
    }
	
  }

  componentWillReceiveProps(nextProps){
	    let selectedNavigation = nextProps.productState.selectedNavigation;
	    let slugType = nextProps.productState.catslugType;
		if(selectedNavigation!==this.state.selectedNavigation){
		  if(selectedNavigation !== '') {
			  var catSlug = selectedNavigation;
			  var subcatSlug = '';
			  if(slugType === 'subcategory') {
				  catSlug = '';
			      subcatSlug = selectedNavigation;
			  }
			  this.props.getProductList(catSlug,subcatSlug,'');
			  $('.addcart_done_maindiv').hide();
		      $('.smiple_product_lk').show();
		  }
		  this.setState({selectedNavigation: selectedNavigation,selectedslugType: slugType});
		}
  }
  
  productsubcatlist() {
	  var subcatlistArr = this.props.productSubcatlist;
	  if(Object.keys(subcatlistArr).length > 0) {
		  hideLoader('productlist-main-div','class');
		  const productDetails = subcatlistArr.map((categories, categoryIndex) => {
			if (categories.products.length > 0) {
				return (<div className="innerproduct" key={categoryIndex}>
							<div className="container-one" >
								<h3 id={categories.pro_subcate_slug}>{stripslashes(categories.pro_subcate_name)}</h3>
								<div className="innerproducts-list-maindiv">
								{this.productList(categories.products, categories.products.length)}
								</div>
							</div>  
						</div>);
			}
		});
		return productDetails;
	  } else {
		  return '';
	  }
  }
  
  chkProStockCls(proSlug,Stock) {
	  
	  var returnText = "innerproduct-col no-stock-product " + proSlug;
	  if(Stock > 0) {
		  returnText = "innerproduct-col " + proSlug;
	  }
	  
	  return returnText;
  }
  
  /* Products List */
  productList(list, productcount) {
	  var imageSource = this.props.productCommon.product_image_source;
	  var slugType = this.state.selectedslugType;
	  var naviSlug = this.state.selectedNavigation;
	  const listProduct = list.map((productDetail, index) => {
			
			var description = (productDetail.product_short_description !== null && productDetail.product_short_description !== '') ? Parser(stripslashes(productDetail.product_short_description)) : '';

			var prodivId = "proIndex-"+productDetail.product_primary_id;

			return (<div className={this.chkProStockCls(productDetail.product_slug,productDetail.product_stock)} id={prodivId} key={index} >
				<div className="innerproduct-item">
					<div className="innerproduct-item-image">
						{callImage(imageSource, productDetail.product_thumbnail, 700, 467, timThumpUrl)}
					</div>
					<div className="innerproduct-button">

					{(productDetail.product_stock > 0 || productDetail.product_stock === null)?((productDetail.product_type === '1')?<a className="btn btn_pink btn_minwid smiple_product_lk" href="javascript:void(0);" onClick={this.addToCartSimple.bind(this,productDetail,'initial')}>Add to Cart</a>:<Link to={"/products/"+slugType+"/"+naviSlug+"/"+productDetail.product_slug} title="Product Details" className="btn btn_pink btn_minwid compo_product_lk">Order Now</Link>):<a className="btn btn_pink btn_minwid disabled" href='javascript:;'>Sold Out</a>}
					
					<div className="addcart_row addcart_done_maindiv">
						<div className="qty_bx">
							<span className="qty_minus" onClick={this.proQtyAction.bind(this,productDetail.product_primary_id, 'decr')}>-</span>
							<input type="text" className="proqty_input" readOnly value="1" />
							<span className="qty_plus" onClick={this.proQtyAction.bind(this,productDetail.product_primary_id, 'incr')}>+</span>
						</div>
						<button className="btn btn_black" onClick={this.addToCartSimple.bind(this,productDetail,'done')}>Done</button>
					</div>
					
					</div> 
					<div className="product-tags-list">
					{(Object.keys(productDetail.product_tag).length > 0)?<ul>
							{ 
							  (productDetail.product_tag).map(
							  (producttag,index1) => {
							  return (<li key={index1}>{producttag.pro_tag_name}</li>) 
							  } )
							}
					</ul>:''}
					</div>
				</div>
				<div className="innerproduct-item-text">
					<h4>{productDetail.product_alias !== '' ? stripslashes(productDetail.product_alias) : stripslashes(productDetail.product_name)}</h4>
						{/*<div className="bakery-review">
						<span className="fa fa-star wppc-checked"></span>
						<span className="fa fa-star wppc-checked"></span>
						<span className="fa fa-star wppc-checked"></span>
						<span className="fa fa-star"></span>
						<span className="fa fa-star"></span>
						</div>*/}
					{(description !== '') ? <p>{stripslashes(description)}</p>:<p className="rmv-border-bottom"></p>}
					<h5>
						<span className="price_nor">${productDetail.product_price}</span>
					</h5>
				</div>
			</div>);
		});
		
		return listProduct;
  }
  
  proQtyAction(indxFlg, actionFlg) {
	  var proqtyInput = $("#proIndex-"+indxFlg).find('.proqty_input').val();
		  proqtyInput = parseInt(proqtyInput);
	  if (actionFlg === 'decr') {
		  proqtyInput = (proqtyInput > 1) ? proqtyInput - 1 : proqtyInput;
	  } else {
		  proqtyInput = proqtyInput + 1;
	  }
	  $("#proIndex-"+indxFlg).find('.proqty_input').val(proqtyInput);
  }
  
  /* add to cart */
  addToCartSimple(productDetail, actionFlg) {
	
	var IndexFlg = productDetail.product_primary_id;
	
	if (actionFlg === 'initial') {
		$("#proIndex-"+IndexFlg).find('.smiple_product_lk').hide();
		$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').show();
		return false;
	} else {
		showLoader('proIndex-'+IndexFlg,'Idtext');
		var availabilityId = cookie.load("defaultAvilablityId");
		/*var availabilityId = deliveryId;*/
		var availabilityName = (availabilityId === deliveryId) ? "Delivery" : "Pickup";
		var isAddonProduct = 'No';
		var productId = productDetail.product_id
		var customerId = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
		var proqtyQty = $("#proIndex-"+IndexFlg).find('.proqty_input').val();
		
		var postObject = {};
		postObject = {
			'app_id': appId,
			"product_id": productId,
			"product_qty": proqtyQty,
			"product_type": 1,
			"availability_id": availabilityId,
			"availability_name": availabilityName,
			"isAddonProduct": isAddonProduct,
			"reference_id":  (customerId === '') ? getReferenceID() : '',
			"customer_id": customerId
		};

		Axios.post(apiUrlV2 + "cart/simpleCartInsert", qs.stringify(postObject)).then(res => {
			hideLoader('proIndex-'+IndexFlg,'Idtext');
			$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').hide();
		    $("#proIndex-"+IndexFlg).find('.smiple_product_lk').show();
			if (res.data.status === "ok") {
				this.props.sateValChange('cartflg', 'yes');
				removePromoCkValue();
				showCustomAlert('success','Great choice! Item added to your cart.');
				/*showCartLst();*/
				this.handleShowAlertFun('success','Great choice! Item added to your cart.');
			} else if (res.data.status === "error") {
				var errMsgtxt = (res.data.message !== '') ? res.data.message : "Sorry! Products can`t add your cart.";
				showCustomAlert('error',errMsgtxt);
				this.handleShowAlertFun('Error',errMsgtxt);
			}
			
            return false;
			
		});
		
	}
	
  }
  
  handleShowAlertFun(header,msg) {
		var magnfPopup = $.magnificPopup.instance;
		showAlert(header, msg, magnfPopup);
		$.magnificPopup.open({
		  items: {
			src: '.alert_popup'
		  },
		  type: 'inline'
		});
  }
	
  componentDidMount() {
	/*console.log('two');*/
  }

  render() {
    return (
		  <div className="productlist-main-div" >
		     
			 <div className="innerproduct_row">
				{this.productsubcatlist()}
			 </div>
			
		  </div >
    )
  }

}

const mapStateTopProps = (state) => {
  var tempArr = Array();var productSubcat = Array();var productCmn = Array();
  if(Object.keys(state.product).length > 0) {
	  var tempArr = (!('productlist' in state.product[0])) ? Array() : state.product[0].productlist;
	  if(Object.keys(tempArr).length > 0) {
		 if(tempArr[0].status === 'ok') {
			 productSubcat = tempArr[0].result_set[0].subcategorie;
			 productCmn = tempArr[0].common;
		 }
	  }
  } 
  
  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);