/* eslint-disable */
import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";
import { stripslashes, hideLoader ,showLoader} from "../Helpers/SettingHelper";
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
var Parser = require('html-react-parser');
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GET_REQUESTPAGEDATA} from '../../actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
/*** DEV SK***/
import {appId,apiUrl,deliveryId,pickupId,cateringId,reservationId,CountryTxt} from "../Helpers/Config";
import {GET_ALL_OUTLETS,GET_RES_AVAILABLE_DATE,GET_RES_AVAILABLE_TIME} from '../../actions';
import cookie from 'react-cookies';
import Datelist from "./Reservationdatelist";
import Timelist from "./Reservationtimelist";
import moment from 'moment';

class Reservation extends Component {

    constructor(props) {
        super(props);
            this.state = {outletlist:[],reservation_outlet_id:'',reservation_outlet_txt:'',reservation_selected_outlet:'',reservation_outlet_postalcode:'',reservation_outlet_name:'',reservation_outletaddress_line:'',reservation_outlet_address:'',adultValue:0,childValue:0,datePicker:1,timePicker:1,reservation_notes:'',datePickershow:0,timePickershow:0,reservation_flag_key:'',reservation_flag_date:'',reservation_date_pre:'',reservation_date_post:'',dateListArr:'',current_day:'',user_reservation_date:'',
            user_reservation_time:(typeof cookie.load('reservation_show_time')!='undefined' && cookie.load('reservation_show_time')!='')?cookie.load('reservation_show_time'):'',
            user_reservation_show_date:'',
            user_reservation_time_type: (typeof cookie.load('reservation_time_type')!='undefined' && cookie.load('reservation_time_type')!='')?cookie.load('reservation_time_type'):'',user_reservation_date_array:''};
            
            var avilablityIdTxt = cookie.load('defaultAvilablityId');
            if(avilablityIdTxt === deliveryId || avilablityIdTxt === pickupId) {
                this.props.history.push('/products');
                return false;
            }
            
            if(avilablityIdTxt === cateringId) {
                this.props.history.push('/catering');
                return false;
            }   
    }

     

    componentDidMount() {
         this.props.getAllOutlets(reservationId);
         if(typeof cookie.load('reservation_adult')!='undefined' && typeof cookie.load('reservation_adult')!='') {
             this.setState({adultValue:cookie.load('reservation_adult')});
         }
         if(typeof cookie.load('reservation_child')!='undefined' && typeof cookie.load('reservation_child')!='') {
             this.setState({childValue:cookie.load('reservation_child')});
         }
         if(typeof cookie.load('reservation_selected_outlet')!='undefined' && typeof cookie.load('reservation_selected_outlet')!='') {
             var Detailsevent =  {"target":{"name":"reservation_outlet_id", "value":cookie.load('reservation_selected_outlet')}};
            this.handleChange(Detailsevent);
         }
         
         if(typeof cookie.load('reservation_notes')!='undefined' && typeof cookie.load('reservation_notes')!='') {
             this.setState({reservation_notes:cookie.load('reservation_notes')});
         }
         
        
    }
    
    componentWillReceiveProps(nextProps) {
        
        if(this.state.outletlist !== nextProps.alloutletslist) {
            
            var ResOutletHtml = "<option value=''> Select Outlet </option>";
            var outletArr = nextProps.alloutletslist;
            if(Object.keys(outletArr).length > 0) {
                outletArr.map((loadData) => 
                    ResOutletHtml+="<option value='"+loadData.oa_outlet_id+'~'+loadData.outlet_postal_code+'~'+stripslashes(loadData.outlet_name).replace("'", "`")+'~'+stripslashes(loadData.outlet_address_line1)+"' >"+stripslashes(loadData.outlet_name)+"</option>"
                 );
                 
                 if(outletArr.length == 1) {
                     var addrsTxt = stripslashes(outletArr[0].outlet_address_line1)+' '+CountryTxt+', '+outletArr[0].outlet_postal_code;
                     this.setState({reservation_outlet_id: outletArr[0].oa_outlet_id, reservation_outlet_postalcode: outletArr[0].outlet_postal_code, reservation_outlet_name: stripslashes(outletArr[0].outlet_name), reservation_outletaddress_line: stripslashes(outletArr[0].outlet_address_line1),reservation_outlet_address: addrsTxt, is_single_outlet: 'Yes'});
                 }
            }
            var outletDropDown = Parser(ResOutletHtml);
            var isSngl = (outletArr.length == 1) ? 'Yes' : 'No';
            this.setState({outletlist: nextProps.alloutletslist, ResOutletDropDownHtml: outletDropDown});
            //this.checkOutletAvailability(nextProps.alloutletslist, isSngl);
            
        }
         
    }
    
    
    /* check outlet is available or not */
    checkOutletAvailability(outletArr, isSingleOutlet) 
    {
         document.getElementById("no_outlet_info").style.display = "none";
         document.getElementById("outlet_list_div").style.display = "block";
         if(outletArr === 0) {
             document.getElementById("no_outlet_info").style.display = "block";
             document.getElementById("outlet_list_div").style.display = "none";
         } else if(outletArr === 1 && isSingleOutlet === 'Yes') {
             document.getElementById("outlet_list_div").style.display = "none";
         }
    }
    
    
    
    
    handleChange(event)  {

        if(event.target.name === 'reservation_outlet_id') {
            
            this.resetErrorClass();
            
            this.setState({reservation_date: '', reservation_time: ''});
            
            showLoader('datepickers_main_div','class');
            
            var ouLtvl = event.target.value;
            
            this.setState({reservation_selected_outlet: ouLtvl});
            
            var outletIdvl = '', outletAddrsLn='', outletAddrs='', outletPostCd='', outletName='', errorInfo='block', temHlval='';
            
            if(ouLtvl !== '' ) {
                var splitedOutletArr = ouLtvl.split('~');
                outletIdvl = splitedOutletArr[0];
                outletPostCd = splitedOutletArr[1];
                cookie.save("reservation_outlet_name", splitedOutletArr[2]);
                outletName = splitedOutletArr[2];
                outletAddrsLn = splitedOutletArr[3];    
                outletAddrs = outletAddrsLn+' '+CountryTxt+', '+outletPostCd;
                errorInfo = 'none';
                
                hideLoader('datepickers_main_div','class');
            }
            
            this.setState({reservation_outlet_id:outletIdvl,reservation_outlet_postalcode:outletPostCd,
                reservation_outlet_name:outletName,reservation_outletaddress_line:outletAddrsLn,reservation_outlet_address:outletAddrs,datePickershow:1
                },function(){this.getResAvailabileDate();}.bind(this));
            
            document.getElementById("error_info_div").style.display = errorInfo;
          // }
        }
        
        if(event.target.name === 'reservation_notes') {
            this.setState({reservation_notes:event.target.value});
        }
        
    }
    
    getResAvailabileDate(){
        var day_avail = Array();
        var selectedDate = '';
        if(typeof this.state.reservation_flag_time!=='undefined' && this.state.reservation_flag_time!=='') {
            selectedDate =  this.state.reservation_flag_time;
        } else if(typeof cookie.load('reservation_date_flag_dateSet') != 'undefined' && cookie.load('reservation_date_flag_dateSet') != '') {
            selectedDate = cookie.load('reservation_date_flag_dateSet');
        }
        
        var flag_next = '';
        if(typeof this.state.reservation_flag_key !== 'undefined' && this.state.reservation_flag_key !== '') {
            flag_next = this.state.reservation_flag_key;
        }
        else if(cookie.load('reservation_date_flag_keySet') != 'undefined' && cookie.load('reservation_date_flag_keySet') != '') {
        
            flag_next = cookie.load('reservation_date_flag_keySet');
        }
        
        if(flag_next=='') {
            selectedDate = '';
        }

        day_avail['app_id'] = appId;
        day_avail['outlet_id'] = this.state.reservation_outlet_id;
        day_avail['flag_key'] = flag_next;
        day_avail['flag_date'] = selectedDate;
        day_avail['flag_date_pre'] = this.state.reservation_date_pre;
        day_avail['flag_date_post'] = this.state.reservation_date_post;
        this.getResAvalDate(day_avail);
        
    }
    
    getResAvalDate=(day_avail)=>{
        this.setState({flag_dateSet:day_avail['flag_date'],flag_keySet:day_avail['flag_key']},function(){ this.props.getResAvailabileDateList(day_avail); }.bind(this));
        
    }
    
    getSelectedDate=(time_avail)=>{
        this.setState({timePickershow:time_avail['timeAvail'],user_reservation_date:time_avail['availability_date'],user_reservation_show_date:time_avail['reservation_show_date'],user_reservation_date_array:time_avail['reservation_seleted_date'], time_flag_next:time_avail['flag_key'], flag_time:time_avail['flag_time']},function(){this.getResAvalTime(time_avail);}.bind(this));
    }
    
    getResAvalTime=(time_avail)=>{
        this.setState({time_flag_next:time_avail['flag_key'], flag_time:time_avail['flag_time']},function(){ this.props.getResAvailabileTimeList(time_avail);}.bind(this));
        
    }
    
    getSelectedTime=(time_value)=>{
        this.setState({user_reservation_time:time_value['reservation_time'],user_reservation_time_type:time_value['reservation_time_type']});
    }
    
    updateAdultQty(type){
        
        var currentAdult = this.state.adultValue;
        var newValue = '';
        
        if(type === 'increment') {
            newValue = parseInt(currentAdult) + 1;
        }else if(type === 'decrement'){
            if(parseInt(currentAdult) > 0){
                newValue = parseInt(currentAdult) - 1;
            }
        }
        $('.error_div_cls').hide();
        this.setState({'adultValue':newValue});
    }
    
    
    updateChildtQty(type){
        
        var currentChild = this.state.childValue;
        var newValue = '';
        
        if(type === 'increment') {
            newValue = parseInt(currentChild) + 1;
        }else if(type === 'decrement'){
            if(parseInt(currentChild) > 0){
                newValue = parseInt(currentChild) - 1;
            }else{
                newValue = 0;
            }
        }
        $('.error_div_cls').hide();
        this.setState({'childValue':newValue});
    }
    
    
    submit_reservation(){
        
        var adultValue = this.state.adultValue;
        var childValue = this.state.childValue;
        
        if(parseInt(adultValue) === 0 && parseInt(childValue) === 0) {
            $('.error_div_cls').show();
            return false;
        }
        
        if(this.state.reservation_outlet_id === '') {
            $('#error_info_div').show();
            return false;
        }
        
        var reservation_time = moment(this.state.user_reservation_time, ["h:mm A"]).format("HH:mm:ss");
        cookie.save("reservation_date", this.state.user_reservation_date);
        cookie.save("reservation_show_date", this.state.user_reservation_show_date);
        cookie.save("reservation_show_time", this.state.user_reservation_time);
        cookie.save("reservation_time",reservation_time) ;
        cookie.save("reservation_time_type", this.state.user_reservation_time_type);
        cookie.save("reservation_adult", this.state.adultValue);
        cookie.save("reservation_child", this.state.childValue);
        cookie.save("reservation_outlet_id", this.state.reservation_outlet_id);
        //cookie.save("reservation_outlet_name", this.state.reservation_outlet_name);
        cookie.save("reservation_selected_outlet", this.state.reservation_selected_outlet);
        cookie.save("reservation_notes", this.state.reservation_notes);
        cookie.save("reservation_date_array", this.state.user_reservation_date_array);
        cookie.save("reservation_time_flag_next", this.state.time_flag_next);
        cookie.save("reservation_time_flag", this.state.flag_time);
        cookie.save("reservation_date_flag_keySet", this.state.flag_keySet);
        cookie.save("reservation_date_flag_dateSet", this.state.flag_dateSet);

        var UserId = (cookie.load('UserId') != '' && cookie.load('UserId') != undefined) ? cookie.load('UserId') : '';  
        if(UserId === '') {
            cookie.save("loginpopupTrigger", 'Yes');
            cookie.save("redirectReservatin", 'Yes');
            this.props.history.push('/');
        }else{
            
            
            this.props.history.push("/reservation-summary");
        }
        
    }
    
    resetErrorClass() {
        $('.choose_event_data, .date_list_maindiv, .breaktime_main_div').removeClass('pkgerror_out');
        $('.choose_event_data, .date_list_maindiv, .breaktime_main_div').removeClass('bdr_bf_select');
        $('.choose_event_data, .date_list_maindiv, .breaktime_main_div').addClass('bdr_bf_select');     
    }
    
    

    render() {
        
        let datePickersDiv = '';
        let timePickersDiv = '';
        let Datetimepic = 'Datetimepic lits';
        
        if(this.state.datePickershow === 1){
            
            Datetimepic = 'choose-datetime-full';
            
            datePickersDiv =  <div className="dateListing">
                                    <Datelist dateListOptions={this.props} stateVal={this.state} dateAvlfun={this.getResAvalDate} SelectedDateOption={this.getSelectedDate}/>
                                </div>
                                    
                                
            
        }else{
            datePickersDiv = '';
        }
        
        if(this.state.timePickershow === 1) {
            timePickersDiv = <div className="timeListing">
                                <Timelist  TimelistOptions={this.props} SelectedTimeOption={this.getSelectedTime} timeAvlfun={this.getResAvalTime} stateVal={this.state} />
                            </div>  
        }   
         
        
        return (<div>
                <div className="container common-top-div reservation-main-div" >
                
                    {/* Header section */}
                    <Header  />
                    
                    {/* innersection_wrap - start */}
                    <div className="innersection_wrap">
                    
                    <div  className="reservation_step_one">
                    
                        <div className="reservation-full">
                            <h2>Reservation</h2>
                            <div className="reservation-counter">
                                <h3>How many pax?</h3>
                                <div className="reservation-counter-full">
                                    <div className="reservation-counter-single">
                                        <div className="reservation-counter-left">
                                            <h4>Adults</h4>
                                        </div>
                                        <div className="reservation-counter-right">
                                            <div className="qty_bx">
                                                <span className="qty_minus" onClick={this.updateAdultQty.bind(this,'decrement')}>-</span>
                                                <input type="text" name="adultValue" value={this.state.adultValue} readOnly /> 
                                                <span className="qty_plus" onClick={this.updateAdultQty.bind(this,'increment')}>+</span> 
                                                    
                                            </div>
                                            { /* <button className="btn btn_black">Done</button> */}
                                        </div>
                                    </div>  
                                    <div className="reservation-counter-single">
                                        <div className="reservation-counter-left">
                                            <h4>Children</h4>
                                        </div>
                                        <div className="reservation-counter-right">
                                            <div className="qty_bx">
                                                <span className="qty_minus" onClick={this.updateChildtQty.bind(this,'decrement')} >-</span>
                                                <input type="text" className="proqty_input" readOnly value={this.state.childValue} />
                                                <span className="qty_plus" onClick={this.updateChildtQty.bind(this,'increment')}>+</span>
                                            </div>
                                            { /* <button className="btn btn_black">Done</button> */}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="error_div_cls">Please Increase Adult OR Child Count.</div>
                                
                            </div>

                            <div className="choose-outlet">
                                
                                <div id='no_outlet_info' className="errorInfo-div">
                                     <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> <span id="info_span">Sorry!. Outlets didn't available for catering..</span>
                                </div>
                                <div id='error_info_div' className="errorInfo-div">
                                     <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> <span id="error_span">Please select the outlet.</span>
                                </div>
                                
                                <h3>Choose Outlet</h3>
                                
                                <div className="form-group custom_select choose_event_data bdr_bf_select" id="outlet_list_div">
                                    
                                    <select value={this.state.reservation_selected_outlet} name="reservation_outlet_id" className="form-control" onChange={this.handleChange.bind(this)}>
                                        {this.state.ResOutletDropDownHtml}
                                    </select>
                                    
                                </div>
                            </div>

                            <div className={Datetimepic}>
                                <div className="datepickers_main_div">
                                    {datePickersDiv}
                                </div>  
                                {timePickersDiv}
                            </div>

                            <div className="special-instructions">
                                <h3>Special Instructions</h3>
                                <textarea placeholder="Enter your special instruction here..." maxLength="500" name="reservation_notes" value={this.state.reservation_notes} onChange={this.handleChange.bind(this)}>
                                </textarea>
                            </div>

                            <div className="reservation-button">
                                <div className="continue">
                                    <a href="javascript:void(0)" className="button" onClick={this.submit_reservation.bind(this)}>continue</a>
                                </div>
                            </div>
                        
                        </div>

                    </div>
                </div>
                
                {/* innersection_wrap - end */}
                
                </div> 
                {/* reservation-main-div - end */}
                
                <Footer />      
               </div>);
    }
}



const mapStateToProps = (state) => {
    
    var outletsArr = Array();
    if(Object.keys(state.alloutlets).length > 0) {
         if(state.alloutlets[0].status === 'ok') {
             outletsArr = state.alloutlets[0].result_set;
         }
    }
    
    var dateListArr = Array();
    var current_day = '';
    if(Object.keys(state.reservationdate).length > 0) {
         if(state.reservationdate[0].status === 'success') {
             dateListArr = state.reservationdate[0].result_set;
             current_day = state.reservationdate[0].current_day;
         }
    }
    
    var timeListArr = Array();
    var times_divide_pre = '';
    var times_divide_next = '';
    if(Object.keys(state.reservationtime).length > 0) {
        
         if(state.reservationtime[0].status === 'success') {
             timeListArr = state.reservationtime[0].result_set.time_set;
             times_divide_pre = state.reservationtime[0].result_set.pre;
             times_divide_next = state.reservationtime[0].result_set.next;
         }
    }
    
    return {
        alloutletslist: outletsArr,
        dateListArr: dateListArr,
        current_day: current_day,
        timeListArr: timeListArr,
        times_divide_pre: times_divide_pre,
        times_divide_next: times_divide_next,
    }
    
}


const mapDispatchToProps = (dispatch) => {
    return {
        getRequestpage: (slug) => {
            dispatch({ type: GET_REQUESTPAGEDATA, slug});
        },
        
        getAllOutlets: (availability) => {
            dispatch({ type: GET_ALL_OUTLETS, availability });
        },
        
        getResAvailabileDateList: (avlDateFlg) => {
            dispatch({ type: GET_RES_AVAILABLE_DATE, avlDateFlg });
        },
        
        getResAvailabileTimeList: (avlTimeFlg) => {
            dispatch({ type: GET_RES_AVAILABLE_TIME, avlTimeFlg });
        },
    }
}
Reservation.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reservation));

 
