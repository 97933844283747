/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import Slider from "react-slick";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import {
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_NORMAL_POPUP,
} from "../../actions";

import orderNowBgImg from "../../common/images/order-now-bg.png";
import orderNowHvrImg from "../../common/images/order-now-hover.png";
import deliveryImg from "../../common/images/delivery.svg";
import takeawayImg from "../../common/images/takeaway.png";
/*import takeawayImg from "../../common/images/takeaway.svg";*/
import cateringImg from "../../common/images/catering.svg";
import reservationImg from "../../common/images/reservation.svg";
import servingImg from "../../common/images/serving-passionately.jpg";
/*import makanIconImg from "../../common/images/muthu-makan-logo.png";*/
import makanIconImg from "../../common/images/makkan-badge.png";

var Parser = require("html-react-parser");

class Home extends Component {
  constructor(props) {
    super(props);
    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      servingPassionately: "",
      servingPassLeft: "",
      servingPassRight: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
    };
    cookie.remove("popuptriggerFrom");
    this.props.getSettings();
    this.props.getStaticBlock();
    this.props.getNormalPopup();
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var servingPas = "";
      var servingPasLft = "";
      var servingPasRgt = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "serving-passionately") {
            servingPas = data.staticblocks_description;
          }
          if (data.staticblocks_slug === "serving-passionately-left") {
            servingPasLft = data.staticblocks_description;
          }
          if (data.staticblocks_slug === "serving-passionately-right") {
            servingPasRgt = data.staticblocks_description;
          }
        });
      }
      servingPas = servingPas !== "" ? Parser(servingPas) : servingPas;
      servingPasLft =
        servingPasLft !== "" ? Parser(servingPasLft) : servingPasLft;
      servingPasRgt =
        servingPasRgt !== "" ? Parser(servingPasRgt) : servingPasRgt;
      this.setState({
        staticblacks: PropsData.staticblack,
        servingPassionately: servingPas,
        servingPassLeft: servingPasLft,
        servingPassRight: servingPasRgt,
      });
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };

  chooseAvailability(availability) {
    if (availability === deliveryId || availability === pickupId) {
      var urlTxt = "https://muthuscurry.revelup.online/store/1/";
      window.open(urlTxt, "_blank").focus();
    } else {
      var defaultAvilTy = cookie.load("defaultAvilablityId");
      if (defaultAvilTy !== availability) {
        var cartTotalItems = cookie.load("cartTotalItems");
        cartTotalItems =
          cartTotalItems != "" && cartTotalItems != undefined
            ? parseInt(cartTotalItems)
            : 0;
        if (defaultAvilTy === cateringId && cartTotalItems > 0) {
          this.setState({ nextavail: availability, normalpopup_status: "no" });
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        } else if (
          cookie.load("orderOutletId") != "" &&
          cookie.load("orderOutletId") != undefined
        ) {
          this.setState({ nextavail: availability, normalpopup_status: "no" });
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      }

      this.setState({ normalpopup_status: "no" });

      var popupIdtxt = "";
      if (availability === deliveryId) {
        var singleOutltSlst = $("#delevery-popup").find(".self_outlet_inner li")
          .length;
        /*popupIdtxt = '#delevery-popup';*/
        popupIdtxt =
          singleOutltSlst === 1
            ? "#delevery-postcode-popup"
            : "#delevery-popup";
      } else if (availability === pickupId) {
        popupIdtxt = "#takeaway-popup";
      } else if (availability === cateringId) {
        cookie.save("defaultAvilablityId", cateringId);
        $.magnificPopup.close();
        this.props.history.push("/catering");
        return false;
      } else if (availability === reservationId) {
        cookie.save("defaultAvilablityId", reservationId);
        $.magnificPopup.close();
        this.props.history.push("/reservation");
        return false;
        /* popupIdtxt = '#comingsoon-popup';*/
      }

      if (popupIdtxt !== "") {
        $.magnificPopup.open({
          items: {
            src: popupIdtxt,
          },
          type: "inline",
        });
      }
    }
  }

  componentDidMount() {
    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop");
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger");
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout");
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("cateringPay") === "initial") {
      cookie.save("cateringPay", "start");
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    setTimeout(function() {
      if ($(".address-list-main").length > 0) {
        $(".address-list-main").mCustomScrollbar();
        $(".address-list-error").mCustomScrollbar();
      }
    }, 1000);

    this.setState({ normalpopup_status: "yes" });
  }

  checkActiveDiv(avlType) {
    var clsTxt = "home-ordernow-single ";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } else if (
      availability == avlType &&
      (avlType === cateringId || avlType === reservationId)
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      /*
		  var normalpopupIds = cookie.load("normalpopupIds");
		  var normalpopupIdsNew =
			normalpopupIds != "" && normalpopupIds != undefined
			  ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
			  : this.state.normalpopupdata.normalpopup_id;
		  var normalpopupIdArr = [];
		  normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
		  cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
		  */

      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function() {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      /*cookie.save("mailpopopuptrg", "yes", { path: "/" });
		  cookie.remove("promoPopupTrigger", { path: "/" });
		  $.magnificPopup.open({
			items: {
			  src: "#promo-check-popup",
			},
			type: "inline",
			midClick: true,
			closeOnBgClick: false,
		  });*/
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      //this.props.history.push("/refpage/promopopup");
    }
  }

  dontshowagain() {
    var normalpopupIds = cookie.load("normalpopupIds");
    var normalpopupIdsNew =
      normalpopupIds != "" && normalpopupIds != undefined
        ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
        : this.state.normalpopupdata.normalpopup_id;
    var normalpopupIdArr = [];
    normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
    cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
    $.magnificPopup.close();
  }

  render() {
    var stcLeftBnr = {
      infinite: true,
      arrows: false,
      dots: true,
      dotsClass: "custom-dots",
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1,
    };

    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    return (
      <div>
        <div className="container common-top-div home-main-div">
          {/* Header section */}
          <Header
            homePageState={this.state}
            cartTriggerFlg={this.state.cartTriggerFlg}
            sateValChange={this.sateValChange}
          />

          {/* Home banner section */}
          <HomeBanner />

          {/* order type section - start */}
          <div className="home-ordernow-section">
            <div className="home-badge-icon">
              <img src={makanIconImg} />
            </div>

            <div className="container-one">
              <div className="home-ordernow-main">
                <div
                  className={this.checkActiveDiv(deliveryId)}
                  onClick={this.chooseAvailability.bind(this, deliveryId)}
                >
                  <img className="blue-round" src={orderNowBgImg} />
                  <img className="green-round" src={orderNowHvrImg} />
                  <div className="home-ordernow-single-img">
                    <img src={deliveryImg} />
                    <h3>Delivery</h3>
                    {/*<a href="javascript:;" onClick={this.chooseAvailability.bind(this, deliveryId)} className="button btn-small">Order Now</a>*/}
                  </div>
                </div>
                <div
                  className={this.checkActiveDiv(pickupId)}
                  onClick={this.chooseAvailability.bind(this, pickupId)}
                >
                  <img className="blue-round" src={orderNowBgImg} />
                  <img className="green-round" src={orderNowHvrImg} />
                  <div className="home-ordernow-single-img">
                    <img src={takeawayImg} />
                    <h3>Takeaway</h3>
                    {/*<a href="javascript:;" onClick={this.chooseAvailability.bind(this, pickupId)} className="button btn-small">Order Now</a>*/}
                  </div>
                </div>
                <div
                  className={this.checkActiveDiv(cateringId)}
                  onClick={this.chooseAvailability.bind(this, cateringId)}
                >
                  <img className="blue-round" src={orderNowBgImg} />
                  <img className="green-round" src={orderNowHvrImg} />
                  <div className="home-ordernow-single-img">
                    <img src={cateringImg} />
                    <h3>Catering</h3>
                    {/*<a href="javascript:;" onClick={this.chooseAvailability.bind(this, cateringId)} className="button btn-small">Order Now</a>*/}
                  </div>
                </div>
                <div
                  className={this.checkActiveDiv(reservationId)}
                  onClick={this.chooseAvailability.bind(this, reservationId)}
                >
                  <img className="blue-round" src={orderNowBgImg} />
                  <img className="green-round" src={orderNowHvrImg} />
                  <div className="home-ordernow-single-img">
                    <img src={reservationImg} />
                    <h3>Reservation</h3>
                    {/*<a href="javascript:;" onClick={this.chooseAvailability.bind(this, reservationId)} className="button btn-small">Order Now</a>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* order type section - End */}

          {/* Featured Products section */}
          <FeaturedProducts sateValChange={this.sateValChange} />

          {/* serving passionately section */}
          <div className="serving-passionately-section">
            {/*this.state.servingPassionately*/}
            {(this.state.servingPassLeft !== "" ||
              this.state.servingPassRight !== "") && (
              <div className="container-one">
                <div className="serving-passionately-main flex">
                  <div className="serving-passionately-left">
                    {this.state.servingPassLeft !== "" && (
                      <Slider {...stcLeftBnr}>
                        {this.state.servingPassLeft}
                      </Slider>
                    )}
                  </div>
                  <div className="serving-passionately-right">
                    {this.state.servingPassRight}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* container home-main-div -- end */}

        {/* Footer section */}
        <Footer />

        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup video-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                  <div className="promo_check">
                    <label>
                      {" "}
                      <input
                        onClick={this.dontshowagain.bind(this)}
                        type="checkbox"
                        value=""
                        name="option"
                      />{" "}
                      Don't show this window again
                    </label>
                  </div>
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading", showNormalPopup, showPromoPopup)}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  return {
    globalsettings: state.settings,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(Home);
