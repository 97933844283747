/* eslint-disable */

if (location.protocol !== "https:") {
  var locationULR = location.href.replace("www.", "");
  location.href = locationULR.replace("http://", "https://www.");
} else if (location.protocol === "https:") {
  var locationULR = location.href;
  if (locationULR.indexOf("www.") < 0) {
    location.href = "https://www." + locationULR.replace("https://", "");
  }
}
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import ProductDetail from "./components/Products/ProductDetail";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Myaccount from "./components/Myaccount/Myaccount";
import Logout from "./components/Myaccount/Logout";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import News from "./components/Pages/News";
import Catering from "./components/Catering/Catering";
import CateringThankyou from "./components/Catering/Thankyou";
import Viewmenu from "./components/Catering/Viewmenu";
import Payment from "./components/Payment/Payment";
import PayThank from "./components/Payment/Thankyou";
import Reservation from "./components/Reservation/Reservation";
import Reservationsummary from "./components/Reservation/Reservationsummary";
import Reservationthankyou from "./components/Reservation/Reservationthankyou";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path={"/products/:slugType/:slugValue/:proSlug"}
          component={ProductDetail}
        />
        <Route path={"/products/:slugType/:slugValue"} component={Products} />
        <Route path="/products" component={Products} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/news" component={News} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/outlets" component={Outlets} />
        <Route
          path="/catering/thankyou/:orderId"
          component={CateringThankyou}
        />
        <Route path="/catering" component={Catering} />
        <Route path="/viewmenu" component={Viewmenu} />
        <Route path="/payment/thankyou" component={PayThank} />
        <Route path={"/payment/:orderId/:paymentId"} component={Payment} />
        <Route path="/payment" component={Payment} />
        <Route path="/logout" component={Logout} />
        <Route path="/reservation" component={Reservation} />
        <Route path="/reservation-summary" component={Reservationsummary} />
        <Route path="/reservation-thankyou" component={Reservationthankyou} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
