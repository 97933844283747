/* Live */

export const appId        = "B2F855E3-CE34-4C34-BD9F-C6E0585B96DD";
export const apiUrl       = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2     = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro    = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const baseUrl      = "https://www.muthuscurry.com/";
export const timThumpUrl  = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const stripeImage  = "https://www.muthuscurry.com/img/stripe_logo.png";

/* Dev */
/*export const appId        = "80F17DFC-AB7A-4E1D-BFE2-1E33578EFE9E";
export const apiUrl       = "http://ccpl.jankosoftworks.com/api/";
export const apiUrlV2     = "http://ccpl.jankosoftworks.com/apiv2/";
export const apiUrlPro    = "http://ccpl.jankosoftworks.com/ninjapro/";
export const apiUrlNotify = "http://ccpl.jankosoftworks.com/Pushorder/";
export const baseUrl      = "http://muthuscurry.jankosoftworks.com/";
export const timThumpUrl  = "http://ccpl.jankosoftworks.com/timthumb.php?src=";
export const blogImageUrl = "http://ccpl.jankosoftworks.com/media/dev_team/blog/";
export const stripeImage  = "http://muthuscurry.jankosoftworks.com/img/stripe_logo.png";*/

export const deliveryId  = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId    = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId    = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId    = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const CountryTxt   = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany    = "Muthus Curry";

export const fbAppId    = "2217773351628980";

export const stripeKey    = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail    = "dev@jankosoft.com";
export const stripeReference    = "muthus";
export const stripeDesc    = "My Checkout";
export const stripeCurrency    = "SGD";
 
