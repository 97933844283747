/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import Axios from 'axios';
import cookie from 'react-cookies';
import Slider from "react-slick";
import { appId, apiUrl, apiUrlV2, deliveryId, timThumpUrl } from "../../Helpers/Config";
import { getReferenceID, stripslashes, showPriceValue, callImage, showLoader, hideLoader, showCustomAlert, showCartLst, showAlert, removePromoCkValue } from "../../Helpers/SettingHelper";
var qs = require('qs');

import { GET_FEATUREPRO, GET_STATIC_BLOCK} from '../../../actions';

var Parser = require('html-react-parser');

class FeaturedProducts extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  users: [],
		  staticblacks: [],
		  featuredProductTitle:'',
		}
		this.props.getStaticBlock();
	  }
	  
	  componentWillMount() {
		this.props.getFeatureProList();
	  }

	  componentWillReceiveProps(PropsData) {
	  	var featured = '';
	  	if(PropsData.staticblack !== this.state.staticblacks) {
			  if(Object.keys(PropsData.staticblack).length > 0) {
			    PropsData.staticblack.map((data, index) => {
				  if(data.staticblocks_slug === 'featured-products') {
					  featured = data.staticblocks_description;
				  }
				});
			  }
			  featured = (featured !== '') ? Parser(featured) : featured;
			  this.setState({staticblacks: PropsData.staticblack, featuredProductTitle: featured});
		
	  	}
	  }
	  
	  proQtyAction(indxFlg, actionFlg) {
		  var proqtyInput = $("#proIndex-"+indxFlg).find('.proqty_input').val();
			  proqtyInput = parseInt(proqtyInput);
		  if (actionFlg === 'decr') {
			  proqtyInput = (proqtyInput > 1) ? proqtyInput - 1 : proqtyInput;
		  } else {
			  proqtyInput = proqtyInput + 1;
		  }
		  $("#proIndex-"+indxFlg).find('.proqty_input').val(proqtyInput);
	  }
	  
	  /* add to cart */
	  addToCartSimple(productDetail, actionFlg) {
		
	    var avilablityId = cookie.load('defaultAvilablityId');
	    var orderOutletId = cookie.load('orderOutletId');
		if(avilablityId!=='' && orderOutletId != undefined && orderOutletId !=='' && orderOutletId != undefined){
			
			var IndexFlg = productDetail.product_primary_id;
		
			if (actionFlg === 'initial') {
				$("#proIndex-"+IndexFlg).find('.smiple_product_lk').hide();
				$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').show();
				return false;
			} else {
				showLoader('proIndex-'+IndexFlg,'Idtext');
				var availabilityId = cookie.load("defaultAvilablityId");
				var availabilityName = (availabilityId === deliveryId) ? "Delivery" : "Pickup";
				var isAddonProduct = 'No';
				var productId = productDetail.product_id
				var customerId = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
				var proqtyQty = $("#proIndex-"+IndexFlg).find('.proqty_input').val();
				
				var postObject = {};
				postObject = {
					'app_id': appId,
					"product_id": productId,
					"product_qty": proqtyQty,
					"product_type": 1,
					"availability_id": availabilityId,
					"availability_name": availabilityName,
					"isAddonProduct": isAddonProduct,
					"reference_id": (customerId === '') ? getReferenceID() : '',
					"customer_id": customerId
				};

				Axios.post(apiUrlV2 + "cart/simpleCartInsert", qs.stringify(postObject)).then(res => {
					hideLoader('proIndex-'+IndexFlg,'Idtext');
					$("#proIndex-"+IndexFlg).find('.addcart_done_maindiv').hide();
					$("#proIndex-"+IndexFlg).find('.smiple_product_lk').show();
					if (res.data.status === "ok") {
						this.props.sateValChange('cartflg', 'yes');
						showCustomAlert('success','Great choice! Item added to your cart.');
						/*showCartLst();*/
						removePromoCkValue();
						this.handleShowAlertFun('success','Great choice! Item added to your cart.');
						return false;
					} else if (res.data.status === "error") {
						var errMsgtxt = (res.data.message !== '') ? res.data.message : "Sorry! Products can`t add your cart.";
						showCustomAlert('error',errMsgtxt);
					}
				});
				
			}
		  
		} else {
			cookie.save("popuptriggerFrom",'FeaturedPro');
			$.magnificPopup.open({
				items: {
				  src: '.order_popup'
				},
				type: 'inline'
			});
			
		}
		
	  }
	  
	  handleShowAlertFun(header,msg) {
			var magnfPopup = $.magnificPopup.instance;
			showAlert(header, msg, magnfPopup);
			$.magnificPopup.open({
			  items: {
				src: '.alert_popup'
			  },
			  type: 'inline'
			});
	  }
	  
	  render() {

		let featureproArr = this.props.featureproduct;
		let featureprolist = [];
		let featureproimagesource = '';

		if(Object.keys(featureproArr).length > 0) {
			if(featureproArr[0].status === 'ok') {
				featureprolist = featureproArr[0].result_set;
				featureproimagesource = featureproArr[0].common.image_source;
			}
		}
		
		var settingsGallery = {
			infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
			dots:true,
			responsive: [{
				breakpoint: 1191,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: false

				}
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: false
				}
			},
			{
				breakpoint: 520,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: false
				}
			}
			]
     };


		
		return (
			    <div >
					{(Object.keys(featureprolist).length > 0)?<div className="featured-products-section">
					  <div className="container-one">
						<div className="featured-products-main">
							{this.state.featuredProductTitle !=="" && this.state.featuredProductTitle !==null ? this.state.featuredProductTitle : ""}
							<div className="featured-products-full">
							   <Slider {...settingsGallery}>
								{ 
								(featureprolist).map(
								  (featurepro,index) => {
									  var prodivId = "proIndex-"+featurepro.product_primary_id;
									  return (
										<div className="featured-products-single" id={prodivId} key={index}>
											<div className="featured-products-image" >
											{callImage(featureproimagesource, featurepro.product_thumbnail, 351, 394, timThumpUrl)}
											
											  <div className="featured-products-ordernow">	
												<div className="addcart_row addcart_done_maindiv">
													<div className="qty_bx">
														<span className="qty_minus" onClick={this.proQtyAction.bind(this,featurepro.product_primary_id, 'decr')}>-</span>
														<input type="text" className="proqty_input" readOnly value="1" />
														<span className="qty_plus" onClick={this.proQtyAction.bind(this,featurepro.product_primary_id, 'incr')}>+</span>
													</div>
													<button className="btn btn_black order_done" onClick={this.addToCartSimple.bind(this,featurepro,'done')}>Done</button>
												</div>
												
												{(featurepro.product_stock > 0 || featurepro.product_stock === null)?((featurepro.product_type === '1')?<a className="button order_nowdiv smiple_product_lk" href="javascript:void(0);" onClick={this.addToCartSimple.bind(this,featurepro,'initial')}>Add to Cart</a>:<Link to={"/products/cat-ftrpro/slug-ftrpro/"+featurepro.product_slug} title="Product Details" className="button order_nowdiv compo_product_lk">Order Now</Link>):<a className="button disabled" href='javascript:;'>Sold Out</a>}
												
											 </div>
											
											</div>
											
											{/*<div className="price-tag">
															<h6>Buy 2 Get 1 Free</h6>
										    </div>*/}
											<div className="related-review-tag">
												<div className="related-tags">
												{(Object.keys(featurepro.product_tag).length > 0)?<ul>
														{ 
														  (featurepro.product_tag).map(
														  (producttag,index1) => {
														  return (<li key={index1}>{producttag.pro_tag_name}</li>) 
														  } )
														}
												</ul>:''}
												</div>
												{/*<div className="review-tags">
													<div className="wppc-rating-full">
														<span className="fa fa-star-o wppc-checked"></span>
														<span className="fa fa-star-o wppc-checked"></span>
														<span className="fa fa-star-o wppc-checked"></span>
														<span className="fa fa-star-o"></span>
														<span className="fa fa-star-o"></span>
													</div>
												  </div>*/}
											</div>
											
											<div className="product-title-discription">
												<div className="product-title-price">
													<div className="product-title">
														<h3>{stripslashes(featurepro.product_name)}</h3>
													</div>
													<div className="product-price">
														<h3>{showPriceValue(featurepro.product_price)}</h3>
													</div>
												</div>
												<div className="product-short-description">
													<p>{(featurepro.product_short_description!='')?Parser(stripslashes(featurepro.product_short_description)):''}</p>
												</div>
											</div>
											
										</div>
									  )
								  }
								)
								}
								</Slider>
							</div>
							<div className="product-view-more">
								<Link to={"/products"} title="Product List" className="button btn-medium">View More</Link>
							</div>
						</div>
					</div></div>:''}
				</div>
					
			   )
	  }	
}

const mapStateTopProps = (state) => {

  var blacksArr = Array();
  if(Object.keys(state.staticblack).length > 0) {
		 if(state.staticblack[0].status === 'ok') {
			 blacksArr = state.staticblack[0].result_set;
		 }
  }	

  return {
  	staticblack: blacksArr,
    featureproduct: state.featureproduct,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeatureProList: () => {
      dispatch({ type: GET_FEATUREPRO });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(FeaturedProducts);
