/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { stripslashes, hideLoader, showAlert} from "../Helpers/SettingHelper";

import { appId, apiUrl, apiUrlV2, timThumpUrl, reservationId } from "../Helpers/Config";


var Parser = require('html-react-parser');

import update from 'immutability-helper'

import { validated } from 'react-custom-validation'
import validator from 'validator'

import axios from 'axios';
import cookie from 'react-cookies';

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import bannerRestImg from "../../common/images/banner-restaurants.jpg";
import bannerRaceCourseImg from "../../common/images/race-course.jpg";

import { GET_PICKUP_OUTLETS, GET_GLOBAL_SETTINGS} from '../../actions';




class Pages extends Component {

    constructor(props) {
        super(props);
		this.state = {outlets:[], globalsettings: [], starttime:'', endtime:''};
    }

    componentDidMount() {
            this.props.getPickupOutlets();      
			this.props.getGlobalSettings();
            $('html, body').animate({ scrollTop: 0 }, 800);
    }
    
    componentWillReceiveProps(nextProps) {


        if(nextProps.outlets !==this.props.outlets){
		   if(nextProps.outlets && nextProps.outlets[0].status == 'ok'){
				 $('#dvLoading').fadeOut(2000);
                 this.setState({outlets: nextProps.outlets[0].result_set});
              }
          }

	 if(nextProps.globalsettings !==this.props.globalsettings){
		   if(nextProps.globalsettings && nextProps.globalsettings[0].status == 'ok'){

                 this.setState({starttime: nextProps.globalsettings[0].result_set.client_start_time,endtime: nextProps.globalsettings[0].result_set.client_end_time});

              }
          }

        
	}
	
 tConvert (time) {

  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { 
    time = time.slice (1);  
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; 
    time[0] = +time[0] % 12 || 12; 
  }
  return time.join (''); 
}

allInOnepopup() {
	/*$.magnificPopup.open({
	  items: {
		src: '#order-popup'
	  },
	  type: 'inline'
	});*/
	
	cookie.save("defaultAvilablityId",reservationId);
	this.props.history.push('/reservation');
}

gotoContacus(outletId) {
	cookie.save("contusOutletId",outletId);
	this.props.history.push('/contact-us');
}

callPhoneOptn(phoneTxt) {
	var resultTxt = '';
	if(phoneTxt !== '') {
		if(phoneTxt.indexOf("+65") !== -1) {
			resultTxt = "tel:"+phoneTxt;
		} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
			resultTxt = "tel:+"+phoneTxt;
		} else {
			resultTxt = "tel:+65"+phoneTxt;
		}
	} else {
		resultTxt = 'javascript:void(0);';
	}
	
	return resultTxt;
}

timingCnt(outletId) {
	/* jankosoft 'Dempsey' => 190 'Suntec' => 191 */
	if(outletId === '218' || outletId === '217') {
		return (<span>Lunch: 11.30AM to 2.30PM <br></br>  Dinner: 6.00PM to 09.30PM</span>);
		
	/* jankosoft 'Race Course' => 192 */	
	} else if(outletId === '216') {
		return (<span>Timing: 10.30 AM to 10.30 PM</span>);	
	} else {
		return (<span>Timing: {this.tConvert(this.state.starttime)} to {this.tConvert(this.state.endtime)}</span>);
	}
}

getOutletData = (dataProp) =>{
  if (dataProp) { 
	   return dataProp.map((item, index) =>{
			   return (<li key={index} >
					   <div className="ourrest_row">
							   <div className="ourrest_img">
							   {(item.outlet_informations !== '') ? Parser(item.outlet_informations) : <img className="media-object" src={bannerRaceCourseImg} /> }
								   
							   </div>
							   <div className="ourrest_info">
									   <h4>{stripslashes(item.outlet_name)}</h4>
									   <p>{item.outlet_address_line1}</p>
									   <p>{(item.outlet_unit_number2 !== '')?'#'+item.outlet_unit_number1+'-'+item.outlet_unit_number2:item.outlet_unit_number1} S ( {item.outlet_postal_code} )</p>
									   <p>
									   {(item.outlet_email !== '') && <span>Email: {item.outlet_email} <br></br></span>}
									   Phone: <span className="phone-show-dskp">{item.outlet_phone}</span><span className="phone-show-mbl"> <a href={this.callPhoneOptn(item.outlet_phone)}>{item.outlet_phone}</a></span> <br></br> 
									   
									   {this.timingCnt(item.outlet_id)}
									   
									   </p>
									<ul className="ourrest_infolinks">
									   <li className="media-links-a">
											   <a href="javascript:void(0);" onClick={this.gotoContacus.bind(this,item.outlet_id)} rel="nofollow" className="readmore font-headings"><i className="fa fa-map-marker"></i>Locate Us </a>           
									   </li>
									   <li className="media-links-b">
											   <a href="javascript:void(0);" onClick={this.allInOnepopup.bind(this)} rel="nofollow" className="readmore font-headings"><i className="fa fa-cutlery"></i>Make A Reservation </a>
										</li>
									</ul>
							   </div>
					   </div>
			   </li>);
		   })
  }        
}
	
	

 render() {
	   return (<div>
			   <Header />
			   <div className="container common-top-div">
				   <div className="inner_banner">
					   <img src={bannerRestImg} alt="" />
				   </div>
				   <div className="white_bgbx">
				   <h3>Our Restaurants</h3>
						<ul className="outletul">
							{this.getOutletData(this.state.outlets)}
						</ul>
				   </div>
			   </div>
			   <Footer />
			   <div id="dvLoading1234"></div>
	   </div>);
 }
 
}



const mapStateToProps = (state) => {
return {
outlets: state.outlets,
globalsettings: state.settings,
}
}
const mapDispatchToProps = (dispatch) => {
return {
getPickupOutlets: () => {
dispatch({ type: GET_PICKUP_OUTLETS});
},
getGlobalSettings: () => {
dispatch({ type: GET_GLOBAL_SETTINGS });
},
}
}
Pages.propTypes = {
history: PropTypes.shape({
push: PropTypes.func.isRequired
}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));

 
