/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';

import { stripslashes, hideLoader, showAlert} from "../Helpers/SettingHelper";

import { appId, apiUrl, apiUrlV2, timThumpUrl, blogImageUrl } from "../Helpers/Config";


var Parser = require('html-react-parser');

import update from 'immutability-helper'

import { validated } from 'react-custom-validation'
import validator from 'validator'

import axios from 'axios';


import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GET_NEWSDATA, GET_REQUESTPAGEDATA} from '../../actions';

import bannerNewsImg from "../../common/images/banner-news.jpg";


class News extends Component {

    constructor(props) {
        super(props);
			this.state = {newsdata:'',pagedetail:''};
    }

    componentDidMount() {
            this.props.getNewsData();  
            this.props.getRequestpage('news');
    	    $('html, body').animate({ scrollTop: 0 }, 800);
    }
    
    componentWillReceiveProps(nextProps) {
		 if(nextProps.newsdata !==this.props.newsdata){
		   if(nextProps.newsdata && nextProps.newsdata[0].status == 'ok'){
				 $('#dvLoading').fadeOut(2000);
                 this.setState({newsdata: nextProps.newsdata[0].result_set});
                
			 }
		 }
		  if(nextProps.pagedata !==this.props.pagedata){
		   if(nextProps.pagedata && nextProps.pagedata[0].status == 'ok'){
				 $('#dvLoading').fadeOut(2000);
                 this.setState({pagedetail: nextProps.pagedata[0].result_set[0].cmspage_description});
                
			 }
		 }
	}
	
	
	getNewdata(newdata){
		if(newdata){
			return newdata.map((item) =>{
            return (<div key={item.blog_title} className="news_lirow">
					
			<div className="news_liimg">
				<img src={blogImageUrl+item.blog_image}/></div>
					{Parser(stripslashes(item.blog_description))}
					</div>);
			})
		}
	}
	

   render() {
		return (<div> 
<div className="container common-top-div pagesList-main-div">
				<Header />
	
						<div className="inner_banner">
							<img alt="" src={bannerNewsImg} />
						</div>
							<div className="white_bgbx">
								<h3>News &amp; Media</h3>
								<p>{(this.state.pagedetail !== '')?Parser(this.state.pagedetail):''}</p>
								<div className="news_list">
									{this.getNewdata(this.state.newsdata)}
								</div>
							</div>
</div>
				<Footer />
				<div id="dvLoading1234"></div>

			</div>
			);	

	}
}



const mapStateToProps = (state) => {
return {
newsdata: state.newsdata,
pagedata: state.pagedata,

}
}
const mapDispatchToProps = (dispatch) => {
return {
getNewsData: () => {
dispatch({ type: GET_NEWSDATA});
},
getRequestpage: (slug) => {
dispatch({ type: GET_REQUESTPAGEDATA, slug});
},
}
}
News.propTypes = {
history: PropTypes.shape({
push: PropTypes.func.isRequired
}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News));

 
