/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { appId, apiUrlV2, CountryTxt, stripeReference, stripeCurrency, stripeImage, stripeCompany } from "../Helpers/Config"
import { showLoader, hideLoader, showAlert, timeToConv12, dateConvFun, showPriceValue, showCustomAlert, stripslashes, getTimeFrmDate } from "../Helpers/SettingHelper";
import cookie from 'react-cookies';
import Moment from 'moment';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
var qs = require('qs');

import { GET_CATERINGHISTORY, GET_ORDERRQUESTLIST, GET_CATERINPAYMENTDATA } from '../../actions';

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
import tickImage from "../../common/images/tick_popup.png";
import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import paymentImg from "../../common/images/payment.png";
import loadingImage from "../../common/images/loading_popup.gif";
var Parser = require('html-react-parser');
var base64 = require('base-64');

class Payment extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {globalSettings: [], staticblacks: [], pageFromTxt:'others', cartTotalItmCount: 0, discountAmount: 0, deliveryCharge: 0, gstPercentage: 0, gstAmount: 0, result_set: [], cartData: [], cartItems: [], final_total_amount: 0, cart_brktime_list: [], status: 'loading', requestAmountArr: [], paymentList: [], requestAmount: 0, balanceAmount: 0, payment_error_txt: '', payment_action: 'Yes', min_payment_inf: 'No', paidAmount: 0, paydisable: true, validateimage: loadingImage,  placingorderimage: loadingImage, payment_id: '', order_id: '', local_orderno: '', tarms_consent: 'no', chk_tarms_contn: 'No', termsAndConditions:''};
		
		
		var orderId = (typeof this.props.match.params.orderId === 'undefined') ? '' : this.props.match.params.orderId;
		var paymentId = (typeof this.props.match.params.paymentId === 'undefined') ? '' : this.props.match.params.paymentId;
		var cateringPay = (typeof cookie.load('cateringPay') === 'undefined') ? '' : cookie.load('cateringPay');
		var userIdTxt = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');

		if(cateringPay === '' && orderId !== '' && paymentId !== '') {
			cookie.save('orderIdTxt', orderId);
			cookie.save('paymentIdTxt', paymentId);
		} else if(cateringPay === 'start') {
			console.log('assafs');
			orderId = (typeof cookie.load('orderIdTxt') === 'undefined') ? '' : cookie.load('orderIdTxt');
		    paymentId = (typeof cookie.load('paymentIdTxt') === 'undefined') ? '' : cookie.load('paymentIdTxt');
		}
		
		if(orderId === '' || paymentId === '') {
			showAlert('Success', 'Sorry!. Invalid payment request.');

			$.magnificPopup.open({
			  items: {
				src: '.alert_popup'
			  },
			  type: 'inline'
			});
			
			this.props.history.push('/');
		}

		if(userIdTxt === '') {
			cookie.save('cateringPay', 'initial');
			this.props.history.push('/');
		}
		
		if(userIdTxt !== '' && orderId !== '' && paymentId !== '') {
			
			$('.dvLoader_inpmt').show();
			
			var paymentId = base64.decode(paymentId);
			var orderId = base64.decode(orderId);
			
			var custmParm = "&order_id="+orderId+"&customer_id="+userIdTxt;
			this.props.getCateringHistory(custmParm);
			this.props.getOrderRequestlist(orderId, paymentId);
			this.props.getCateringPaymentDetails(orderId);
		}
		
    }
	
	componentWillReceiveProps(nxtProps) {
		
		var orderId = (typeof this.props.match.params.orderId === 'undefined') ? '' : this.props.match.params.orderId;
		var paymentId = (typeof this.props.match.params.paymentId === 'undefined') ? '' : this.props.match.params.paymentId;
		
		if(cookie.load('cateringPay') === 'start') {
			orderId = (typeof cookie.load('orderIdTxt') === 'undefined') ? '' : cookie.load('orderIdTxt');
		    paymentId = (typeof cookie.load('paymentIdTxt') === 'undefined') ? '' : cookie.load('paymentIdTxt');
		}
		
		if(this.state.globalSettings !== nxtProps.settingsArr) {
			this.setState({globalSettings: nxtProps.settingsArr});
		}
		
		if(this.state.cartData !== nxtProps.cateringOrderData || this.state.requestAmountArr !== nxtProps.cateringRequestAmount || this.state.paymentList !== nxtProps.cateringPaymentList) {
			
			var paymentId = base64.decode(paymentId);
			var orderId = base64.decode(orderId);
			$('.dvLoader_inpmt').fadeOut(500);
			
			this.setState({status: 'ok', cartItems : nxtProps.cateringOrderItems, cartData: nxtProps.cateringOrderData, requestAmountArr: nxtProps.cateringRequestAmount, paymentList: nxtProps.cateringPaymentList, payment_id: paymentId, order_id: orderId}, function () { this.makeBrkTimeList(); }.bind(this));
		}
		
		if(nxtProps.cateringOrderStatus === 'no') {
			showAlert('Error', 'Invalid order detail OR order Id.');
			$.magnificPopup.open({
				items: {
				  src: '.alert_popup'
			    },
			    type: 'inline'
			});
			this.props.history.push('/');
		}
		
		if(nxtProps.cateringPayRequestStatus === 'no') {
			showAlert('Error', 'Invalid payment request.');
			$.magnificPopup.open({
				items: {
				  src: '.alert_popup'
			    },
			    type: 'inline'
			});
			this.props.history.push('/');
		}

		if(nxtProps.staticblack !== this.state.staticblacks) {
		  var termsConditions = '';
		  if(Object.keys(nxtProps.staticblack).length > 0) {
			nxtProps.staticblack.map((data, index) => {
			  if(data.staticblocks_slug === 'terms-and-conditions') {
				  termsConditions = data.staticblocks_description;
				  return '';
			  }
			});
		  }
		  termsConditions = (termsConditions !== '') ? Parser(termsConditions) : termsConditions;
		  this.setState({staticblacks: nxtProps.staticblack, termsAndConditions: termsConditions});
		  
		  setTimeout(function(){
				$(".checkout-terms-and-condition").mCustomScrollbar();
		  }, 800);
	    }
		
	}
    
	componentDidMount() {

	}
	
	CheckTarmsContChk() {
		var TarmsCont = this.state.tarms_consent;
		var chkBox = (TarmsCont === 'yes') ? true : false;
		return chkBox;
    }
	
	changeTarmsContChk() {
		var TarmsCont = this.state.tarms_consent;
		var swpValue = (TarmsCont === 'yes') ? 'no' : 'yes';
		$('.tarms-error').hide();
		this.setState({tarms_consent: swpValue});
	}
	
	makeBrkTimeList() {
		var cartDetailsSate = this.state.cartData;
		var cartItems = this.state.cartItems;
		var cartBrkTmList = [];
		if(Object.keys(cartDetailsSate).length > 0 && Object.keys(cartItems).length > 0) {
			
			var localOrderNo = cartDetailsSate.order_local_no;
			var grandTotalAmt = cartDetailsSate.order_total_amount;
			var balanceAmount = cartDetailsSate.order_total_amount;
			
			var paymentListArr= this.state.paymentList;
			if(Object.keys(paymentListArr).length > 0) {
				var temBlnc = (Object.keys(paymentListArr.balanceamount).length > 0)?paymentListArr.balanceamount[0].balanceamount:0;
				if(temBlnc !== '' && parseFloat(temBlnc)>0) {
					balanceAmount = parseFloat(temBlnc);
				}
			}

			var paidAmount = this.state.paidAmount;
			if(parseFloat(grandTotalAmt) > parseFloat(balanceAmount)) {
				paidAmount = parseFloat(grandTotalAmt) - parseFloat(balanceAmount);
			}

			var requestAmountArr= this.state.requestAmountArr;
			var requestAmount= this.state.requestAmount;
			if(Object.keys(requestAmountArr).length > 0) {
				var tmpAmt = requestAmountArr.order_catering_request_amount;
				if(tmpAmt !== '' && parseFloat(tmpAmt)>0) {
					requestAmount = parseFloat(tmpAmt);
				}
			}
			
			var payment_error_txt = '';
			var payment_action = this.state.payment_action;
			if(parseFloat(requestAmount) > parseFloat(balanceAmount)) {
				payment_error_txt = 'Sorry!. You can not use this payment request, so please contact to administrator.';
				payment_action = 'No';
			}

			var min_payment_inf = this.state.min_payment_inf;
			if(parseFloat(requestAmount) < 0.50) {
				min_payment_inf = 'Yes';
			} else {
				min_payment_inf = 'No';
			}

			this.setState({balanceAmount: balanceAmount, paidAmount: paidAmount, requestAmount: requestAmount, payment_error_txt: payment_error_txt, payment_action: payment_action, min_payment_inf: min_payment_inf, local_orderno: localOrderNo});

			var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
			var breaktimeCount = cartDetailsSate.order_breaktime_count;
			if(breaktimeEnable === 'Yes' && parseInt(breaktimeCount)>0) {
				var tempKeyArr = Array();
				var tempKey = 0;
				for (var key in cartItems) {
					var chkKey = cartItems[key].item_breaktime_indexflag;
					if(!(chkKey in tempKeyArr)) {
						tempKeyArr[chkKey] = tempKey;
						var tempItemArr = Array();
						var tempItemLst = [];
						var intVl = tempKey + 1;
						var time_lable = "Break "+intVl;
						tempItemArr['cart_detaile'] = {
							'break_time_lable' 		: time_lable,
							'break_time_index' 		: cartItems[key].item_breaktime_indexflag,
							'break_time_start' 		: cartItems[key].item_breaktime_started,
							'break_time_end' 		: cartItems[key].item_breaktime_ended,
						};
						tempItemLst[0] = cartItems[key] 
						tempItemArr['cart_items'] = tempItemLst;
						cartBrkTmList[tempKey] = tempItemArr;
						tempKey++;
					} else {
						var extKey = tempKeyArr[chkKey];
						cartBrkTmList[extKey]['cart_items'].push(cartItems[key]);
					}
				}
			}
		}
		
		this.setState({cart_brktime_list: cartBrkTmList});
		
		setTimeout(function(){ if ($(".mCustomScrollbar").length > 0) {
			$(".mCustomScrollbar").mCustomScrollbar();
			} }, 800);

	}

	getCartDetList() {
		var cartDetailsSate = this.state.cartData;
		var cartBrktimeLst = this.state.cart_brktime_list;
		if(Object.keys(cartDetailsSate).length > 0) {
			var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
			if(breaktimeEnable === 'Yes' && Object.keys(cartBrktimeLst).length > 0) {
				return (this.cartBrkTimeList());
			} else {
				return (this.cartListOnly());
			}
		}
	}

	handlerActTab(indx) {
		var ariaAxp = $("#headingTb"+indx).find('a').attr('aria-expanded');
		if(ariaAxp === 'true') {
			$("#headingTb"+indx).addClass("act");
		} else {
			$("#headingTb"+indx).removeClass("act");
		}
		$('.panel-heading').not($("#headingTb"+indx)).removeClass("act");
		$('.panel-collapse').not($("#collapse"+indx)).removeClass("in");
	}

	cartListOnly() {
		var cartItems = this.state.cartItems;
		return (<div className="panel panel-default">
					<div className="panel-heading act" role="tab" id="headingTb0" onClick={this.handlerActTab.bind(this,0)}>
						<h4 className="panel-title">
							<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="true" href="#collapse0" className="">Order Item Details</a>
						</h4>
					</div>
					<div id="collapse0" className="panel-collapse collapse mCustomScrollbar in" aria-expanded="true" href="#collapse0">
						<div className="panel-body">
						 
							{ this.cartItemlist(cartItems) }
						 
						</div>
					</div>	
				</div>);
	}

	cartBrkTimeList() {
		var cartBrktimeLst = this.state.cart_brktime_list;
		
		const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) =>
								  <div className="panel panel-default" key={indx}>
									<div className={(indx===0)?"panel-heading act":"panel-heading"} role="tab" id={"headingTb"+indx} onClick={this.handlerActTab.bind(this,indx)}>
										<h4 className="panel-title">
											<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded={(indx===0)?"true":"false"} href={"#collapse"+indx}  className={(indx===0)?"":"collapsed"}>
												{cartBrk['cart_detaile'].break_time_lable} : <span className="hr">{timeToConv12(cartBrk['cart_detaile'].break_time_start)}</span></a>
										</h4>
									</div>
									<div id={"collapse"+indx} className={(indx===0)?"panel-collapse collapse mCustomScrollbar in":"panel-collapse collapse mCustomScrollbar"} aria-expanded={(indx===0)?"true":"false"} href={"#collapse"+indx}>
										<div className="panel-body">
										 
											{ this.cartItemlist(cartBrk['cart_items']) }
										 
										</div>
									</div>	
								  </div>); 
		return cartBrktmhtml;
	}

	cartItemlist(itemLst) {

		if(Object.keys(itemLst).length > 0) {
			
		const cartlsthtml = itemLst.map((cartLst, indx1) =>
								  <div key={indx1}>
								  
								   {/* package div start */}
									<div className="package">
									
									  <div className="row package_cnt">
									    <div className="col-sm-4 col-xs-4">
											<a href="javascript:void(0)">
											<span className="title">{cartLst.item_name}</span></a>
										</div>
										<div className="col-sm-4 col-xs-4">
										    <div className="qtybxs"> {cartLst.item_qty} pax </div>
									   </div>
									   <div className="col-sm-4 col-xs-4">
									        <div className="amt">
									         <span>{showPriceValue(cartLst.item_total_amount)}</span>
									        </div>
									   </div>
									 </div>	

									 {this.showModifiers(cartLst.modifiers)}	
									 
									</div>
									{/* package div end */}
									
									<div className="brline"></div>
	
									{(Object.keys(cartLst.addons_setup).length > 0)?<div className="addon"><h4>ADD-ONS</h4>{this.addonsSetupLst(cartLst)}</div>:""}

									<div className="brline"></div>

									{(Object.keys(cartLst.setup).length > 0)?this.setupDet(cartLst):""}
									
									<div className="brline"></div>
									
									{(cartLst.item_specification !== '')?this.specialNotes(cartLst):""}

								  </div>); 
		return cartlsthtml;
		
		}
		
	}

	showModifiers(mdfLst) {

		if(Object.keys(mdfLst).length > 0) {

			const mdflsthtml = mdfLst.map((mdfLt, indx3) =>
								  <div key={indx3} className="package-list">
									<p className="one">{mdfLt.order_modifier_name}</p>
									<p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
								  </div>);
			return mdflsthtml;
		}

	}

	showMdfValues(mdfValLst) {
		var mdfValueText = '';	
		if(Object.keys(mdfValLst).length > 0) {
			var tempVl = Object.keys(mdfValLst).length - 1;
			for (var key in mdfValLst) {
				var cartmdfprice = (parseFloat(mdfValLst[key].order_modifier_price)>0) ? ' ( '+showPriceValue(mdfValLst[key].order_modifier_price)+' )' : '';
				mdfValueText += (parseInt(tempVl) === parseInt(key)) ? stripslashes(mdfValLst[key].order_modifier_name)+cartmdfprice : stripslashes(mdfValLst[key].order_modifier_name)+cartmdfprice+', ';
			}
		}
		return mdfValueText;
	}
	
	addonsSetupLst(cartLstDat) {
			var addonsSet = cartLstDat.addons_setup;
			const addonhtml = addonsSet.map((addonSt, indx4) =>
								  <div key={indx4} className="row addon_list">
									<div className="col-sm-6 col-xs-9">		
									  <h5><a href="javascript:void(0)">
										<span className="title">{addonSt.as_setup_title}</span>
									  </a></h5>
									  {this.addonsSetupValues(addonSt.addons_setup_values)}
									</div>		
									<div className="col-sm-6 col-xs-3">	
									{/*<div className="amt">
										  <span>{showPriceValue(addonSt.as_setup_amount)}</span>
									</div>*/}
									</div>
								  </div>);
			return addonhtml;
	}

	addonsSetupValues(addonsVal) {
		if(Object.keys(addonsVal).length > 0) {
			const addonValhtml = addonsVal.map((addonVl, indx5) =>
								  <p key={indx5}>
									{addonVl.asv_setup_val_title} ({addonVl.asv_setup_val_qty}X {(parseFloat(addonVl.asv_setup_val_price)>0)?showPriceValue(addonVl.asv_setup_val_price):''})
								  </p>);
			return addonValhtml;
		}
	}

	setupDet(cartLstDat) {

		var setupDt = cartLstDat.setup;
		var setupLbl = '';
		var setupPrice = 0;

		for (var key in setupDt) {
			setupLbl = setupDt[key].os_setup_type;
			setupPrice += parseFloat(setupDt[key].os_setup_total_price);
		}

		return (<div className="buffet_setup">
				  <div className="row">
					<div className="col-sm-6 col-xs-9">		
						<h5><a href="javascript:void(0)"><span className="title">{setupLbl} Setup</span></a></h5>		
					</div>
					<div className="col-sm-6 col-xs-3">	
					{/*<div className="amt">
							<span>{showPriceValue(setupPrice)}</span>
					</div>*/}
					</div>
				</div>
				<div className="row">
				 <div className="col-sm-10 col-xs-10">	
					{this.setupList(setupDt)}	
				 </div>
				</div>
			</div>);

	}

	setupList(setupDats) {
		if(Object.keys(setupDats).length > 0) {
			const setupDathtml = setupDats.map((setupVl, indx6) =>
								  <p key={indx6}>
									{setupVl.os_setup_name} : {setupVl.os_setup_description} {(parseFloat(setupVl.os_setup_total_price)>0)?' ( '+showPriceValue(setupVl.os_setup_total_price)+' )': ''}
								  </p>);
			return setupDathtml;
		}
	}

	specialNotes(cartLstDat) {

		var special_not = cartLstDat.item_specification;
		
		return (<div className="buffet_setup" style={{paddingTop: '0px'}}>
				  <div className="row">
					<div className="col-sm-6 col-xs-8">		
						<h5><a href=""><span className="title">Special Notes</span></a></h5>		
					</div>
					<div className="col-sm-6 col-xs-4"></div>
				</div>
				<div className="row">
				 <div className="col-sm-10 col-xs-10">
				 <p>{special_not}</p>
				 </div>
				</div>
			</div>);

	}

	cartHallData() {
		var cartDetailsMn = this.state.cartData;
		if(Object.keys(cartDetailsMn).length > 0) {
		 if(cartDetailsMn.order_venue_type === "hall") {
		   return (<div className="panel panel-default">
					<div className="panel-heading" role="tab" id="headingTbH1" onClick={this.handlerActTab.bind(this,'H1')}>
						<h4 className="panel-title">
							<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" href="#collapseH1" className="collapsed">Catering Hall Details</a>
						</h4>
					</div>
					<div id="collapseH1" className="panel-collapse fixed-height-cls collapse mCustomScrollbar" aria-expanded="false" href="#collapseH1">
						<div className="panel-body">
						
						 <div className="buffet_setup" style={{padding: '0px'}}>
						 <div className="row">
							<div className="col-sm-6 col-xs-8">		
								<h5><a href="javascript:void(0)"><span className="title">{cartDetailsMn.order_hall_name}</span></a></h5>
							</div>
							<div className="col-sm-6 col-xs-4">	
								<div className="amt">
									<span>{showPriceValue(cartDetailsMn.order_hall_charges)}</span>
								</div>
							</div>
						 </div>
						 </div>
						 
						</div>
					</div>	
				</div>);
		 }
		}
	}

	getCartTotalDet() {
		var cartDeta = this.state.cartData;
		if(Object.keys(cartDeta).length > 0) {
		
		var promotionApplied = cartDeta.order_discount_applied;
		var promotionAmount = (cartDeta.order_discount_amount !== '') ? parseFloat(cartDeta.order_discount_amount) : 0;
		
		var paymentAction = this.state.payment_action;
		var minPaymentInf = this.state.min_payment_inf;
		
		return (<div className="total_amt">
					<div className="subtotal">
						<div className="col-sm-6 col-xs-6">SUBTOTAL</div>
						<div className="col-sm-6 rt">{showPriceValue(cartDeta.order_sub_total)}</div>
					</div>
					{(promotionApplied === 'Yes') && <div className="subtotal">
						<div className="col-sm-6 col-xs-6">Discount Amount</div>
						<div className="col-sm-6 rt">{showPriceValue(promotionAmount)}</div>
					</div>}
					{(parseFloat(cartDeta.order_tax_charge) > 0) && <div className="subtotal">
						<div className="col-sm-6 col-xs-6">GST ({cartDeta.order_tax_charge} %)</div>
						<div className="col-sm-6 rt">{showPriceValue(cartDeta.order_tax_calculate_amount)}</div>
					</div>}
					<div className="total">
						<div className="col-sm-6 col-xs-6">Total</div>
						<div className="col-sm-6 rt">{showPriceValue(cartDeta.order_total_amount)}</div>
					</div>
					<div className="subtotal">
						<div className="col-sm-6 col-xs-6">Paid Amount</div>
						<div className="col-sm-6 rt">{showPriceValue(this.state.paidAmount)}</div>
					</div>
					<div className="subtotal">
						<div className="col-sm-6 col-xs-6">Balance Amount</div>
						<div className="col-sm-6 rt">{showPriceValue(this.state.balanceAmount)}</div>
					</div>
					<div className="total" style={{fontSize: '20px'}}>
						<div className="col-sm-6 col-xs-6">Payable Amount</div>
						<div className="col-sm-6 rt">{showPriceValue(this.state.requestAmount)}</div>
					</div>
					{(paymentAction === 'Yes' && minPaymentInf === 'Yes')?<div className="total" style={{fontSize: '12px', textAlign:'right', color: 'red'}}>
						<div className="col-sm-12 col-xs-12"> * Minimum Payable Amount : S$0.50</div>
					</div>:''}
					<div className="clearfix"></div>
				</div>);
		}
	}

	showUnitNumber(unit1,unit2) {
		unit1 = (typeof unit1 !== 'undefined') ? unit1 : '';
		unit2 = (typeof unit2 !== 'undefined') ? unit2 : '';
		
		if(unit1 !== '') {
			var unitNo = (unit2 !== '') ? unit1+' - '+unit2 : unit1;
		} else {
			var unitNo = unit2;
		}
		
		return (unitNo !== '')?'#'+unitNo:'';
	}
	
	setActiveTab = (tabid) => {
		cookie.save('fromOtherPageFlg', tabid);
		this.props.history.push('/');
	}
	
	closeAlrt() {
		$('.customized_pick_msgdiv').hide();
	}
	
	/* post stripe account */
    onToken = (token) => {

        $.magnificPopup.open({
            items: {
                src: '.processing'
            },
            type: 'inline'
        });

        /*  load process html */
        var postObject = {};
        postObject = {
            'app_id': appId,
            'token': token.id,
            'stripe_envir': this.state.globalSettings.stripe_envir,
            'stripe_key': this.state.globalSettings.stripe_public_key,
            "customer_id": cookie.load('UserId'),
            "paid_amount": this.state.requestAmount,
            "outlet_name": '',
            "payment_reference": 'muthuscatrg'
        }


        axios.post(apiUrlV2 + "payment/stripeTokenPay", qs.stringify(postObject)).then(res => {
            if (res.data.status === "ok") {
                var captureData = res.data.result_set;
				if(Object.keys(captureData).length > 0) {
					this.setState({ validateimage: tickImage });
					this.makePayment(captureData, 'Stripe');
				} else {
					$.magnificPopup.close();
					showCustomAlert('error', 'Stripe response was empty.');
				}
            }
            else if (res.data.status === "error") {
				    $.magnificPopup.close();
					/*showCustomAlert('error', res.data.message);*/
					showCustomAlert('error', 'Sorry!, geting error response from stripe.');
            }
        });

    }
	
	/* show online payment mode loading */
    onlinePaymentLoading() {
        
            return (<div className="process_col">
                <div className="process_left">
                    <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                    <h5>Processing</h5>
                    <p>Validating card information.</p>
                </div>
            </div>);
    }
	
	/* show online payment mode loading */
    orderBCLoading() {

        return (<div className="process_col">
            <div className="process_left">
                <img src={this.state.placingorderimage} />
            </div>
            <div className="process_right">
                <h5>Processing</h5>
                <p>Placing your order now.</p>
            </div>
        </div>);

    }
    /**/
	
	 /* stripe description  */
    stripeDescription() {
        //userName
        if (typeof cookie.load('UserLname') !== 'undefined' && cookie.load('UserLname') !== "") {
            return cookie.load('UserFname') + " " + cookie.load('UserLname');
        } else {
            return cookie.load('UserLname');
        }
    }
	
	/* this fuction used to post order to biz panel */
    makePayment(captureData = '', payGetWayType = '') {

        if (cookie.load('UserId') == '' || cookie.load('UserId') == undefined) {
			this.props.history.push('/');
            return false;
        }
		
		var paymentDetails = {};
		paymentDetails = { payment_type:'stripe', payment_status:'Success', payment_reference_1:captureData.payment_reference_1, payment_transaction_amount:captureData.payment_transaction_amount, payment_reference_2:captureData.payment_reference_2, payment_date:captureData.payment_date, payment_currency:captureData.payment_currency }
		
		 /*  load process html */
        var postObject = {};
        postObject = {
            'app_id': appId,
            'request_tableid': this.state.payment_id,
            'order_id': this.state.order_id,
            "payment_details": JSON.stringify(paymentDetails)
        }

		if(this.state.payment_id !== '' && this.state.order_id !== '') {
			
			axios.post(apiUrlV2 + "cateringorders/request_catering", qs.stringify(postObject)).then(res => {
				if (res.data.status === "ok") {
					this.setState({ placingorderimage: tickImage });
					this.deletePaymentCookie();
					const{history} = this.props;
					setTimeout(function () {
						$.magnificPopup.close();
						history.push("/payment/thankyou");
					}, 1000);
				}
				else if (res.data.status === "error") {
					showAlert('Error', res.data.message);
				}
			});
		 
		} else {
			showAlert('Error', 'Payment id was null.');
		}
		
	}
	
   deletePaymentCookie() {

		var localOrderNo = this.state.local_orderno;
		var requestAmount = this.state.requestAmount;
		cookie.save('payOrderid', localOrderNo);
		cookie.save('payAmount', requestAmount);
   
        cookie.remove('cateringPay');
        cookie.remove('orderIdTxt');
        cookie.remove('paymentIdTxt');
		
    }
	
	orderPayValidaion(){
		   
		    /*var TarmsCont = this.state.tarms_consent;*/
		    var TarmsCont = this.state.chk_tarms_contn;
			if(TarmsCont === 'Yes'){	
				$.magnificPopup.open({
					items: {
						src: '#pay-conf-popup'
					},
					type: 'inline'
				});
			} else if(TarmsCont !== "Yes" && this.state.termsAndConditions !== ''){
				/*$('.tarms-error').show();*/
				$(".tarms_chkbox_div").addClass('err_tarms_chk');
			}				
			
			return true;
	}

  CheckTarmsContnChk() {
		var tarmsContn = this.state.chk_tarms_contn;
		var chkBox = false;
		if(tarmsContn === 'Yes') {
			chkBox = true;
		}
		return chkBox;
  }
	
  changeTarmsContnChk() {
		var tarmsContn = this.state.chk_tarms_contn;
		if(tarmsContn === 'Yes') {
			this.setState({chk_tarms_contn: 'No'});
		} else {
			this.setState({chk_tarms_contn: 'Yes'});
		}
		$('.tarms_chkbox_div').removeClass('err_tarms_chk');
  }

  render() {
	
		var orderDetail = this.state.cartData;
		var custAdrr = (typeof orderDetail.order_customer_address_line1 !== 'undefined') ? orderDetail.order_customer_address_line1 : '';
		custAdrr = custAdrr.replace('Singapore', ''); 
		
		var stripePkKey = (Object.keys(this.state.globalSettings).length > 0) ? this.state.globalSettings.stripe_public_key : '';
		
		return (<div>
		
				{/* catering-payment-page - start */}
				<div className="container common-top-div catering-payment-page">
		  
			    {/* Header start */}
				<Header />
				{/* Header End */}
				
				{/* innersection_wrap - start */}
				<div className="innersection_wrap tnk-you">
				 
					 <div className="mainacc_toptext tick make-pay-div">
							<h2>Make Your Payment</h2>
							<h4>Requested Payment Amount - {showPriceValue(this.state.requestAmount)}</h4>
					 </div>
				
					{/* order-detail-maindiv - start */} 
					 <div className="thank-order-detaildiv">
		  
					 <div className="tnk-detail text-center">
							<h2>YOUR ORDER DETAILS</h2>
							<div className="tnk-order">
								<h3>Order No - {orderDetail.order_local_no}</h3>
								<p>Order placed at :  {Moment(orderDetail.order_created_on).format('DD-MM-YYYY hh:mm A')}</p>
							</div>
					 </div>	
			
					  <div className="tnk-delivery">
					  
					    <div className="delivery-cart-div">
							<div className="cart-direction">
							  <img className="cart-direction-left" src={scotterImg} />
							  <img className="cart-direction-right" src={mapImg} />
							</div>
							<div className="cart_row cart-header-first pay-cart-header">
								<div className="row-replace">
									<div className="col-xs-6 cart_left text-left">
										<h4>Order Handling By</h4>
										{(orderDetail.outlet_name !== '' && orderDetail.outlet_name !== undefined) && <p>{stripslashes(orderDetail.outlet_name)}</p>}
										<p>{orderDetail.outlet_address_line1}</p>
										<p>{this.showUnitNumber(orderDetail.outlet_unit_number1,orderDetail.outlet_unit_number2)} {CountryTxt+" "+orderDetail.outlet_postal_code}</p>
									</div>
									<div className="col-xs-6 cart_right text-right">
										<h4>Catering Location</h4>
										{(orderDetail.order_hall_name !== '')?<p>{orderDetail.order_hall_name}</p>:''} 
										<p>{custAdrr}</p>
										{(orderDetail.order_customer_address_line2 !== '')?<p>{orderDetail.order_customer_address_line2}</p>:''}							
										<p>{this.showUnitNumber(orderDetail.order_customer_unit_no1,orderDetail.order_customer_unit_no2)} {CountryTxt+" "+orderDetail.order_customer_postal_code}</p> 
										{/*<p>{this.showUnitNumber(orderDetail.order_customer_unit_no1,orderDetail.order_customer_unit_no2)}</p>*/} 
									</div>
								</div>
							</div>
						</div>
					
						<div className="delivery_total delivery_total_number">
							<div className="delivery_total_left">				
								<h2>Event Date</h2>
								<h4 className="checkoutDate">{Moment(orderDetail.order_date).format('DD/MM/YYYY')}</h4>
							</div>
							<div className="delivery_total_left delivery_total_right">
								<h2>Event time</h2>
								<h4 className="checkoutTime">{Moment(orderDetail.order_date).format('hh:mm A')}</h4>
							</div>
						</div>
						
						<div className="order_details">
							<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
							{this.getCartDetList()}
							{this.cartHallData()}
							</div>
						</div>
						
						{this.getCartTotalDet()}
					
					</div>
					
					<div className="chk-payment mk-catering-payment">
					    
						<div className="form-group custom-tarms-div">

							{(this.state.termsAndConditions !== '') && <div>	
							<div className="checkout-terms-and-condition">
							{this.state.termsAndConditions}
							</div>
							<div className="custom_checkbox tarms_chkbox_div">
								 <input type="checkbox" name="tarms_chk" onClick={this.changeTarmsContnChk.bind(this)} defaultChecked={this.CheckTarmsContnChk()} />
								 <span>
								 I have read and accept terms and conditions
								 </span> 
							</div>
							</div>}


							{/*<div className="custom_checkbox custom_checkbox_content">
								<input type="checkbox" id="tarms_consent" onChange={this.changeTarmsContChk.bind(this)} checked={this.CheckTarmsContChk()} /> <span></span>
								<div className="sign_reg">
									<p>Yes, I have read and accept the <a className="sign_reg_uline" href="javascript:void(0);">Terms and conditions</a>.</p>
								</div>
							</div>
							<div className="tarms-error">Please accept terms and conditions</div>*/}

					    </div>
						
						<div className="chk-payment-row">
							<span className="text-lbl">Select Your Payment Method</span>
							<div className="chk-payment-col">
								<ul className="chk-payment-col-radio">
								  <li>
									<input type="radio" id="card" name="payment-mode" value='2' defaultChecked />
									<label></label>
									<div className="radio_con"><img src={paymentImg} /></div>
								  </li>
								</ul>
							</div>
						</div>
					</div>
					
					<div className="tnk-chk-order">
					
					{(this.state.payment_action === 'Yes' && stripePkKey !== '')?<a href="javascript:;" onClick={this.orderPayValidaion.bind(this)} className="button" title="Continue">Continue</a>:<a className="button" href="javascript:void(0);" disabled={this.state.paydisable} >Continue </a>}
					
					</div>
		  
			    </div>
				   {/* order-detail-maindiv - end */}
				 
			</div>
			{/* innersection_wrap - end */}
				 
		</div>
		{/* payment-main-div - end */}
		

		<div id="processing-popup" className="white-popup mfp-hide popup_sec processing">
			<div className="pouup_in">
				<h3 className="title1 text-center">We Are Processing Your Order</h3>
				<div className="process_inner">

					{this.onlinePaymentLoading()}
					{this.orderBCLoading()}


				</div>
			</div>
		</div>
		
		{/*Payment confirm popup Start*/}
		<div id="pay-conf-popup" className="white-popup mfp-hide popup_sec pay-conf-popup">
			<div className="custom_alert">
				<div className="custom_alertin">
					<div className="alert_height">
						<div className="alert_header">Alert!</div>
							<div className="alert_body">
							<p>Awesome ! You are all set</p>
							<div className="alt_btns">
								
								{(this.state.payment_action === 'Yes' && stripePkKey !== '') && <StripeCheckout name={stripeCompany} image={stripeImage} description={this.stripeDescription()} token={this.onToken}
                                        stripeKey={stripePkKey} amount={(this.state.requestAmount * 100)}
                                        email={cookie.load('UserEmail')} currency={stripeCurrency}  > <a href="javascript:;" className="button" title="Proceed To Payment">Proceed To Payment</a>
                                    </StripeCheckout>}
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/*payment confirm popup - End*/}
	  
		<Footer />	
		<div id="dvLoading" className="dvLoader_inpmt"></div>
	   </div>);

  }
}

const mapStateTopProps = (state) => {	
  var resultSetArr = Array();var orderStatus = '';
  var orderData = Array();var orderItems = Array();
  if(Object.keys(state.cateringhistory).length > 0) {
	  var resultSetArr = (!('result_set' in state.cateringhistory[0])) ? Array() : state.cateringhistory[0].result_set;
	  if(state.cateringhistory[0].status === 'ok' && Object.keys(resultSetArr).length > 0) {
			 orderData = resultSetArr[0];
			 orderItems = resultSetArr[0]['items'];
			 orderStatus = 'yes';
	  } else {
		     orderStatus = 'no';
	  }
  }
  
  var requestAmountArr = Array();var requestStatus = '';
  if(Object.keys(state.orderrequestlist).length > 0) {
	  var requestAmountArr = (!('result_set' in state.orderrequestlist[0])) ? Array() : state.orderrequestlist[0].result_set;
	  if(state.orderrequestlist[0].status === 'ok' && Object.keys(requestAmountArr).length > 0) {
			 requestStatus = 'yes';
	  } else {
		     requestStatus = 'no';
	  }
  }
  
  var paymentLstArr = Array();
  if(Object.keys(state.paymentlist).length > 0) {
		 if(state.paymentlist[0].status === 'ok') {
			 paymentLstArr = state.paymentlist[0].result_set;
		 }
  }
  
  var globalSettings = Array();
  if(Object.keys(state.settings).length > 0) {
	  if(state.settings[0].status === 'ok') {
		  globalSettings = state.settings[0].result_set;
	  }
  }

  var blacksArr = Array();
  if(Object.keys(state.staticblack).length > 0) {
		 if(state.staticblack[0].status === 'ok') {
			 blacksArr = state.staticblack[0].result_set;
		 }
  }
  
  return {
	cateringOrderData: orderData,
	cateringOrderItems: orderItems,
    cateringOrderStatus: orderStatus,
    cateringRequestAmount: requestAmountArr,
    cateringPayRequestStatus: requestStatus,
    cateringPaymentList: paymentLstArr,
	settingsArr: globalSettings,
	staticblack: blacksArr
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCateringHistory: (historyparams) => {
      dispatch({ type: GET_CATERINGHISTORY, historyparams });
    },
	getOrderRequestlist: (orderId, paymentId) => {
      dispatch({ type: GET_ORDERRQUESTLIST, orderId, paymentId });
    },
	getCateringPaymentDetails: (orderId) => {
      dispatch({ type: GET_CATERINPAYMENTDATA, orderId });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Payment)); 
