/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { appId, apiUrl, cateringId, apiUrlV2, baseUrl, timThumpUrl } from "../../Helpers/Config"
import axios from 'axios';
import { getReferenceID, showLoader, hideLoader, stripslashes, callImage, showAlert, smoothScroll } from "../../Helpers/SettingHelper";
import { GET_CATEGORY_LIST, GET_OWNVENUE_CATEGORY, GET_HALL_CATEGORY } from '../../../actions';

/*import swichImg from "../../../common/images/swich.png";*/
import swichImg from "../../../common/images/switch-orange.png";

class Categorylist extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cateVenueFlag: '', catePrimaryId: '', category_id: '', category_name: '', categorylist: [], commonimgurl: [], ownvenuecategory: [], catownvenueimgurl: [], hallcategory: [], cathallimgurl: []
        };
		
		/*this.props.getCategorylist();*/
		this.props.getOwnvenueCategory();
		this.props.getHallCategory();
    }
	
	componentWillReceiveProps(nxtProps) {
		
		if(Object.keys(nxtProps).length > 0) {
			var categoryStateArr = nxtProps.categoryState;
			for (var key in categoryStateArr) {
				this.state[key] = categoryStateArr[key];
			}
		}
		
		if(this.state.categorylist !== nxtProps.categorylist) {
			hideLoader('categorylist_norecord','class');
			this.setState({categorylist: nxtProps.categorylist, commonimgurl: nxtProps.catcommonimgurl});
		}
		
		if(this.state.ownvenuecategory !== nxtProps.ownvenuecategory) {
			hideLoader('categorylist_norecord','class');
			this.setState({ownvenuecategory: nxtProps.ownvenuecategory, catownvenueimgurl: nxtProps.catownvenueimgurl});
		}
		
		if(this.state.hallcategory !== nxtProps.hallcategory) {
			hideLoader('categorylist_norecord','class');
			this.setState({hallcategory: nxtProps.hallcategory, cathallimgurl: nxtProps.cathallimgurl});
		}
		
	}

    componentDidMount() {
		showLoader('categorylist_norecord','class');
	}

	eventTypePopup() {
		$.magnificPopup.open({
		  items: {
			src: '#venue-popup'
		  },
		  type: 'inline',
		  showCloseBtn: false,
		  closeOnBgClick: false
		});
	}
	
	getCategoryListHtml() {
		var categoryPryId = this.state.catePrimaryId;
		var catgPryId = cookie.load('catering_categoryPryId');
		categoryPryId = (categoryPryId === '') ? catgPryId : categoryPryId;
		if(this.state.categorylist.length > 0) {
			
			const Categoryhtml = this.state.categorylist.map((category, index) =>
								 <li className="pkg_gcol catbox-bottom-list" key={index}>
								   <div className={(category.pro_cate_primary_id === categoryPryId)?"pkg_gitem category-active":"pkg_gitem"}>
								     <div className="pkg_gimg catbox-bottom-img">
										{callImage(this.state.commonimgurl.category_image_url, category.pro_cate_image, 436, 330, timThumpUrl)}
										<div className="pkg_ginfo catbox-bottom-info">
											<h4>{stripslashes(category.pro_cate_name)}</h4>
											<p>{(category.pro_cate_short_description !== '') ? stripslashes(category.pro_cate_short_description) : ""}</p>
										</div>
								     </div>
									 <div className="pkg_gbtsec text-center catbox-bottom">
									 {/*<a href="javascript:void(0)" onClick={this.viewMenuDetails.bind(this, category)} className="button viewmenu-but" title="Download Menu">View Menu</a>*/}
										<a href="javascript:void(0)" onClick={this.saveCategoryDetails.bind(this, category)} className="button ordernw-but" title="Place Order">Place Order</a>
									 </div>
								   </div>
								  </li>); 
			return Categoryhtml;					  
								  
		} else {
			return (<div className="categorylist_norecord"> Catering category was not available. </div>);
		}
		
	}
	
	getOwnVenueCategoryListHtml() {
		
		var cateVenueFlag = this.state.cateVenueFlag;
		var categoryVenu = cookie.load('catering_eventType');
		cateVenueFlag = (cateVenueFlag === '') ? categoryVenu : cateVenueFlag;

		var categoryPryId = this.state.catePrimaryId;
		var catgPryId = cookie.load('catering_categoryPryId');
		categoryPryId = (categoryPryId === '') ? catgPryId : categoryPryId;

		categoryPryId = (cateVenueFlag !== 'venue') ? '' : categoryPryId;
		
		if(this.state.ownvenuecategory.length > 0) {
			
			const Categoryhtml = this.state.ownvenuecategory.map((category, index) =>
								 <li className={(category.pro_cate_primary_id === categoryPryId)?"pkg_gcol catbox-bottom-list category-active":"pkg_gcol catbox-bottom-list"} key={index}>
								   <div className="pkg_gitem">
								     <div className="pkg_gimg catbox-bottom-img">
										{callImage(this.state.catownvenueimgurl.category_image_url, category.pro_cate_image, 436, 330, timThumpUrl)}
										<div className="pkg_ginfo catbox-bottom-info">
											<h4>{stripslashes(category.pro_cate_name)}</h4>
											<p>{(category.pro_cate_short_description !== '') ? stripslashes(category.pro_cate_short_description) : ""}</p>
										</div>
								     </div>
									 <div className="pkg_gbtsec text-center catbox-bottom">
									 {/*<a href="javascript:void(0)" onClick={this.viewMenuDetails.bind(this, category)} className="button viewmenu-but" title="Download Menu">View Menu</a>*/}
										<a href="javascript:void(0)" onClick={this.saveCategoryDetails.bind(this, category, 'venue')} className="button ordernw-but" title="Place Order">Place Order</a>
									 </div>
								   </div>
								  </li>); 
			return Categoryhtml;					  
								  
		} else {
			return (<div className="categorylist_norecord"> Catering category was not available. </div>);
		}
		
	}
	
	getHallCategoryListHtml() {
		
		var cateVenueFlag = this.state.cateVenueFlag;
		var categoryVenu = cookie.load('catering_eventType');
		cateVenueFlag = (cateVenueFlag === '') ? categoryVenu : cateVenueFlag;

		var categoryPryId = this.state.catePrimaryId;
		var catgPryId = cookie.load('catering_categoryPryId');
		categoryPryId = (categoryPryId === '') ? catgPryId : categoryPryId;

		categoryPryId = (cateVenueFlag !== 'hall') ? '' : categoryPryId;
		
		if(this.state.hallcategory.length > 0) {
			
			const Categoryhtml = this.state.hallcategory.map((category, index) =>
								 <li className={(category.pro_cate_primary_id === categoryPryId)?"pkg_gcol catbox-bottom-list category-active":"pkg_gcol catbox-bottom-list"} key={index}>
								   <div className="pkg_gitem">
								     <div className="pkg_gimg catbox-bottom-img">
										{callImage(this.state.cathallimgurl.category_image_url, category.pro_cate_image, 436, 330, timThumpUrl)}
										<div className="pkg_ginfo catbox-bottom-info">
											<h4>{stripslashes(category.pro_cate_name)}</h4>
											<p>{(category.pro_cate_short_description !== '') ? stripslashes(category.pro_cate_short_description) : ""}</p>
										</div>
								     </div>
									 <div className="pkg_gbtsec text-center catbox-bottom">
									 {/*<a href="javascript:void(0)" onClick={this.viewMenuDetails.bind(this, category)} className="button viewmenu-but" title="Download Menu">View Menu</a>*/}
										<a href="javascript:void(0)" onClick={this.saveCategoryDetails.bind(this, category, 'hall')} className="button ordernw-but" title="Place Order">Place Order</a>
									 </div>
								   </div>
								  </li>); 
			return Categoryhtml;					  
								  
		} else {
			return (<div className="categorylist_norecord"> Catering category was not available. </div>);
		}
		
	}
	
	
	saveCategoryDetails(categoryDet,eventtype) {

		if(Object.keys(categoryDet).length > 0) {
			
			this.props.sateValChangefun('cateVenueFlag', eventtype);
			this.props.sateValChangefun('catePrimaryId', categoryDet.pro_cate_primary_id);
			this.props.sateValChangefun('category_id', categoryDet.pro_cate_id);
			this.props.sateValChangefun('category_name', stripslashes(categoryDet.pro_cate_name));
			this.props.sateValChangefun('venueTypeChanged', 'Yes');
			
			cookie.save('catering_eventType', eventtype);
			cookie.save('catering_categoryPryId', categoryDet.pro_cate_primary_id);
			cookie.save('catering_categoryId', categoryDet.pro_cate_id);
			cookie.save('catering_categoryName', stripslashes(categoryDet.pro_cate_name));
			smoothScroll(100,50);
			
			this.setState({cateVenueFlag: eventtype, catePrimaryId: categoryDet.pro_cate_primary_id, category_id: categoryDet.pro_cate_id, category_name: stripslashes(categoryDet.pro_cate_name)}, function () { this.props.setActiveTab(2); }.bind(this));
			
		}
	}
	
	
	viewMenuDetails(categoryDet) {

		if(Object.keys(categoryDet).length > 0) {
			
			cookie.save('view_categoryPryId', categoryDet.pro_cate_primary_id);
			cookie.save('view_categoryId', categoryDet.pro_cate_id);
			cookie.save('view_categoryName', stripslashes(categoryDet.pro_cate_name));
			
			this.props.history.push('/viewmenu');
		}
	}
	
    /* ViewProducts */
	
	setPropActiveTab(tagvalue) {
		var tabflag = this.props.categoryState.active_tabflag;
		if(parseInt(tabflag) !== parseInt(tagvalue)) {
			this.props.setActiveTab(parseInt(tagvalue));
		} else {
			this.props.sateValChangefun('active_tabflag', 0);
		}
	}
	
	showEvtDiv(typeval) {
		var venuetypeInit = this.props.categoryState.venue_type_Initail;
			venuetypeInit = (venuetypeInit !== '') ? venuetypeInit : 'outdoor';
		var divShowText = 'none';
		if(typeval === 'outdoor' && venuetypeInit === 'outdoor') {
			 divShowText = 'block';
		} else if(typeval === 'indoor' && venuetypeInit === 'indoor') {
			 divShowText = 'block';
		}
		return divShowText;
	}

    render() {

        return (<div>
				 {/*<h4 className={"tab_mobtrigger tab_mobtrigger_cls "+this.props.showsActiveHdr(1)} onClick={this.setPropActiveTab.bind(this,1)} >CATEGORIES<i></i></h4>*/}	
		           <div className="tab_mobrow" style={{display:this.props.showsActiveDiv(1)}}>
						<div className="pkg_gridsec" style={{paddingTop:'10px'}}>
						
						 <div className="innerproduct" style={{display:this.showEvtDiv('outdoor')}}>
						    <h3 id="our-signature-dish">Outdoor Catering <span onClick={this.eventTypePopup.bind(this)}><img src={swichImg} /></span></h3>
							<ul className="row-pgk-list">
								{/*this.getCategoryListHtml()*/}
								{this.getOwnVenueCategoryListHtml()}
							</ul>
						 </div>
						 
						 <div className="innerproduct" style={{display:this.showEvtDiv('indoor')}}>
							<h3 id="our-signature-dish">Indoor Catering <span onClick={this.eventTypePopup.bind(this)}><img src={swichImg} /></span></h3>
							<ul className="row-pgk-list">
								{this.getHallCategoryListHtml()}
							</ul>
						 </div>	
							
						</div>	
					</div>
				</div>);
     }
}

const mapStateTopProps = (state) => {
  var categoryRst = Array();var commonImg = Array();
  if(Object.keys(state.categorylist).length > 0) {
	  if(state.categorylist[0].status === 'ok') {
		 categoryRst = state.categorylist[0].result_set;
		 commonImg = state.categorylist[0].common;
	  }
  }
  
  var owncategoryRst = Array();var owncommonImg = Array();
  if(Object.keys(state.ownvenuecategory).length > 0) {
	  if(state.ownvenuecategory[0].status === 'ok') {
		 owncategoryRst = state.ownvenuecategory[0].result_set;
		 owncommonImg = state.ownvenuecategory[0].common;
	  }
  }
  
  var hallcategoryRst = Array();var hallcommonImg = Array();
  if(Object.keys(state.hallcategory).length > 0) {
	  if(state.hallcategory[0].status === 'ok') {
		 hallcategoryRst = state.hallcategory[0].result_set;
		 hallcommonImg = state.hallcategory[0].common;
	  }
  }
  
  return {
    categorylist: categoryRst,
    catcommonimgurl: commonImg,
	ownvenuecategory: owncategoryRst,
    catownvenueimgurl: owncommonImg,
	hallcategory: hallcategoryRst,
    cathallimgurl: hallcommonImg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategorylist: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
	getOwnvenueCategory: () => {
      dispatch({ type: GET_OWNVENUE_CATEGORY });
    },
	getHallCategory: () => {
      dispatch({ type: GET_HALL_CATEGORY });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Categorylist));
